import React from "react";
import { Link } from "react-router-dom";

import config from "../settings";
import Decheterie from "../affichages/decheterie";
import { QDQSPrestataire, SinoePrestataire } from "../affichages/prestataire";
import withRouter from "../utils/router";

function AffichageEquipement(props) {
    if (!props.data.type) {
        return <p>Non disponible.</p>;
    }
    const serviceType = props.data.type.cle_detail_page.toLowerCase();
    const source = props.typeSource.toLowerCase();
    if (serviceType === "decheterie") {
        return <Decheterie data={props.data} dechetsPro={false} />;
    } else if (serviceType === "prestataire" && source === "ordec") {
        return <QDQSPrestataire data={props.data} />;
    } else if (serviceType === "prestataire" && source === "sinoe") {
        return <SinoePrestataire data={props.data} />;
    } else if (serviceType === "recyclerie" && source === "ordec") {
        return <QDQSPrestataire data={props.data} />;
    } else if (serviceType === "recyclerie" && source === "sinoe") {
        return <Decheterie data={props.data} dechetsPro={true} />;
    } else {
        return <p>En cours</p>;
    }
}

class DetailEquipement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: undefined,
            loaded: true,
            isLoading: false,
            // TODO: unused!
            error: undefined,
            fromUrl:
                this.props.params.typeSource &&
                this.props.params.typeEquipement &&
                this.props.params.idEquipement,

            typeSource: this.props.typeSource ?? this.props.params.typeSource,
            typeEquipement:
                this.props.typeEquipement ?? this.props.params.typeEquipement,
            idEquipement:
                this.props.idEquipement ?? this.props.params.idEquipement,
        };
    }

    getEquipement(typeSource, typeEquipement, idEquipement) {
        this.setState({
            data: undefined,
            loaded: false,
            isLoading: true,
            error: undefined,
        });
        fetch(
            config.apiLink +
                "detail/" +
                typeSource +
                "/" +
                typeEquipement +
                "/" +
                idEquipement,
            {
                method: "GET",
                credentials: "same-origin",
                mode: "cors",
            }
        )
            .then((response) => response.json())
            .then((json) => {
                this.setState({
                    data: json.details,
                    loaded: true,
                    isLoading: false,
                });
                document.title =
                    "QDQS - " +
                    json.details["name"] +
                    " (" +
                    typeEquipement.charAt(0).toUpperCase() +
                    typeEquipement.slice(1) +
                    ")";
            })
            .catch((e) =>
                this.setState({
                    loaded: true,
                    isLoading: false,
                    error: e,
                })
            );
    }

    componentDidMount() {
        if (
            this.state.typeSource &&
            this.state.typeEquipement &&
            this.state.idEquipement &&
            !this.state.isLoading
        ) {
            this.getEquipement(
                this.state.typeSource,
                this.state.typeEquipement,
                this.state.idEquipement
            );
        }
    }

    render() {
        let error = "";
        let output = "";
        if (
            (!this.state.data ||
                (Object.keys(this.state.data).length === 0 &&
                    Object.getPrototypeOf(this.state.data) ===
                        Object.prototype)) &&
            this.state.loaded
        ) {
            error = <p>Aucun équipement n'a été trouvé dans la base !</p>;
        } else if (
            (!this.state.data ||
                (Object.keys(this.state.data).length === 0 &&
                    Object.getPrototypeOf(this.state.data) ===
                        Object.prototype)) &&
            !this.state.loaded
        ) {
            error = <p>Chargement en cours...</p>;
        } else if (this.state.data !== undefined) {
            output = (
                <AffichageEquipement
                    typeSource={this.state.typeSource}
                    typeEquipement={this.state.typeEquipement}
                    data={this.state.data}
                />
            );
        }
        return (
            <div>
                {this.state.fromUrl ? (
                    <p>
                        <Link to="/">Retour à la page principale</Link>
                    </p>
                ) : (
                    ""
                )}
                {error}

                {output}
            </div>
        );
    }
}

export default withRouter(DetailEquipement);
