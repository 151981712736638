import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

const AuthVerify = (props) => {
    const location = useLocation();
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedJwt = parseJwt(token);

            if (decodedJwt.exp * 1000 < Date.now()) {
                props.logOut();
            }
        } else {
            props.logOut();
        }
    }, [location]);

    return <div></div>;
};

export default AuthVerify;
