import React, { Component } from "react";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    ScaleControl,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const attribution =
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

const tileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

const defaultMapState = {
    lat: 45.748358,
    lng: 4.784154,
    zoom: 10,
    minZoom: 5,
};

export default class Map extends Component {
    constructor(props) {
        super(props);

        if (this.props.centerCity) {
            this.state = {
                lat: this.props.centerCity.lat,
                lng: this.props.centerCity.lng,
                minZoom: 5,
            };
        } else {
            this.state = defaultMapState;
        }
        this.state.outerBounds = this.getBounds();
        this.state = { ...this.state, hiddenActorsTypesId: [] };
    }

    getPositionsFromRadiusAndBearing(bearing, radius) {
        const bearing_rad = (bearing * Math.PI) / 180;
        const distance = radius;

        const EARTH_RADIUS = 6378.137;

        const initial_position = {
            latitude: this.props.centerCity.lat,
            longitude: this.props.centerCity.lng,
        };

        const init_lat = (initial_position.latitude * Math.PI) / 180;
        const init_lon = (initial_position.longitude * Math.PI) / 180;

        const final_lat =
            (180 / Math.PI) *
            Math.asin(
                Math.sin(init_lat) * Math.cos(distance / EARTH_RADIUS) +
                    Math.cos(init_lat) *
                        Math.sin(distance / EARTH_RADIUS) *
                        Math.cos(bearing_rad)
            );

        const final_lon =
            (180 / Math.PI) *
            (init_lon +
                Math.atan2(
                    Math.sin(bearing_rad) *
                        Math.sin(distance / EARTH_RADIUS) *
                        Math.cos(init_lat),
                    Math.cos(distance / EARTH_RADIUS) -
                        Math.sin(init_lat) * Math.sin(final_lat)
                ));
        return { lat: final_lat, long: final_lon };
    }

    getBounds() {
        // cf. https://stackoverflow.com/a/68961818

        const top_right = this.getPositionsFromRadiusAndBearing(
            45,
            this.props.radius
        );
        const top_lat = top_right.lat,
            top_lon = top_right.long;
        const left_bottom = this.getPositionsFromRadiusAndBearing(
            -135,
            this.props.radius
        );
        const right_lat = left_bottom.lat,
            right_lon = left_bottom.long;
        return [
            [top_lat, top_lon],
            [right_lat, right_lon],
        ];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.radius !== this.props.radius) {
            this.setState({
                outerBounds: this.getBounds(),
            });
        }
    }

    hideActorType(actorTypeId) {
        if (this.state.hiddenActorsTypesId.includes(actorTypeId)) {
            const newElements = this.state.hiddenActorsTypesId.filter(
                (id) => id !== actorTypeId
            );
            this.props.callbackHiddenElements(newElements);
            this.setState({
                hiddenActorsTypesId: newElements,
            });
        } else {
            const newElements = [
                ...this.state.hiddenActorsTypesId,
                actorTypeId,
            ];
            this.props.callbackHiddenElements(newElements);
            this.setState({
                hiddenActorsTypesId: newElements,
            });
        }
    }

    render() {
        let markers = [];
        let legendMarkers = [];

        for (const key in this.props.pointsOfInterest) {
            const actorTypeDetails = this.props.getActorType(key);

            const isHidden = this.state.hiddenActorsTypesId.includes(key);

            if (this.props.pointsOfInterest[key].length > 0) {
                legendMarkers.push(
                    <div
                        key={"marker-legend-logo-" + key}
                        className="icon"
                        onClick={(e) => this.hideActorType(key)}
                    >
                        <img
                            className="marker-legend"
                            src={actorTypeDetails.logo}
                            alt={actorTypeDetails.nom}
                        />
                        {isHidden && <div className="striked-logo"></div>}
                    </div>
                );
                legendMarkers.push(
                    <div
                        key={"marker-legend-text-" + key}
                        onClick={(e) => this.hideActorType(key)}
                        className={isHidden ? "striked-text" : undefined}
                    >
                        {actorTypeDetails.nom}
                        {actorTypeDetails.details && " - "}
                        {actorTypeDetails.details}
                    </div>
                );
            }

            if (isHidden) {
                continue;
            }

            this.props.pointsOfInterest[key].forEach((poi) => {
                let position = [0, 0];
                if (
                    poi.meta_data &&
                    poi.meta_data.gps_x &&
                    poi.meta_data.gps_y
                ) {
                    position = [poi.meta_data.gps_x, poi.meta_data.gps_y];
                } else if (
                    poi.meta_data &&
                    poi.meta_data.lat &&
                    poi.meta_data.lng
                ) {
                    position = [poi.meta_data.lat, poi.meta_data.lng];
                }

                let className = "leaflet-div-icon";
                if (
                    this.props.activeActor &&
                    this.props.activeActor === poi["acteur_id"]
                ) {
                    className += " marker-active";
                }
                const iconEquip = new L.Icon({
                    iconUrl: actorTypeDetails.logo,
                    iconAnchor: [0, 30],
                    anchorOrigin: "bottom-left",
                    popupAnchor: [15, -30],
                    iconSize: new L.Point(30, 30),
                    className: className,
                });

                markers.push(
                    <Marker
                        icon={iconEquip}
                        position={position}
                        key={
                            "marker-" +
                            poi["acteur_id"] +
                            "-" +
                            poi["acteur_type_id"]
                        }
                    >
                        <Popup>
                            {poi.nom}
                            <br />
                            <a
                                href="#"
                                onClick={() =>
                                    this.props.callbackDetails(
                                        key,
                                        poi["acteur_id"],
                                        poi["type_source"]
                                    )
                                }
                            >
                                Détails
                            </a>
                        </Popup>
                    </Marker>
                );
            });
        }

        return (
            <div className="map-elements-container">
                <div className="map-container">
                    <MapContainer
                        center={[this.state.lat, this.state.lng]}
                        bounds={this.state.outerBounds}
                        updateWhenZooming={false}
                        updateWhenIdle={true}
                        preferCanvas={true}
                        minZoom={this.state.minZoom}
                    >
                        <TileLayer attribution={attribution} url={tileUrl} />
                        <ScaleControl position="bottomleft" imperial={false} />

                        {markers}
                    </MapContainer>
                </div>
                <div className="map-legend">
                    <h3>Légende</h3>

                    <div className="markers-legend">{legendMarkers}</div>
                </div>
            </div>
        );
    }
}
