import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

import config from "../settings.js";
import { ModalMessage } from "../utils/common.js";

const VISIBLE_FIELDS = [
    "acteur_id",
    "nom",
    "contenu",
    "actor_types",
    "wastes",
    "meta_data",
    "date_maj",
];
const COLUMNS_VERBOSE_NAMES = {
    acteur_id: "# de l'acteur",
    nom: "Nom de l'acteur",
    contenu: "Description associée",
    actor_types: "Type d'acteur",
    wastes: "Déchets associés",
    meta_data: "Méta données associées",
    date_maj: "Dernière mise à jour",
};
const WIDTH_FIELDS = {
    acteur_id: 80,
    nom: 300,
    actor_types: 150,
    contenu: 350,
    date_maj: 175,
    wastes: 300,
    meta_data: 300,
};

export default function Actors({ token }) {
    const [actors, setActors] = useState(false);
    const [metaData, setRelevantMetaData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [queryMessage, setQueryMessage] = useState(undefined);
    const [errorState, setErrorState] = useState("success");

    const getActors = () => {
        if (isLoading) {
            return;
        }
        setLoading(true);
        // récupère les données auprès de l'API
        fetch(config.apiLink + "actors/", {
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => response.json())
            .then((json) => {
                setActors(json["actors"]);
                setRelevantMetaData(json["metadata_config"]);
            })
            .catch((e) => {
                setActors([]);
                setQueryMessage(
                    "Impossible de récupérer les données. Contactez l'administrateur !"
                );
                setErrorState("error");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!actors) {
            getActors();
        }
    }, [token, actors]);

    if (isLoading) {
        return (
            <div>
                <p>Chargement en cours...</p>
            </div>
        );
    }

    const printObject = (object) => {
        let output = [];
        for (let key in object) {
            const value = object[key];
            if (!metaData.includes(key)) {
                continue;
            }
            if (typeof value === "object") {
                output.push(
                    <li>
                        <strong>{key}</strong> : {printObject(value)}
                    </li>
                );
            } else {
                output.push(
                    <li>
                        <strong>{key}</strong> : {value}
                    </li>
                );
            }
        }
        return <ul className="meta_data_actor">{output}</ul>;
    };
    const printArray = (list) => {
        if (list.length === 0 || !list[0].dechet_id) {
            return "Aucun déchet associé";
        }
        return (
            <ul className="meta_data_actor">
                {list.map((item) => {
                    return <li>{item.dechet_nom}</li>;
                })}
            </ul>
        );
    };

    let columns = [];

    if (actors && actors.length > 0) {
        VISIBLE_FIELDS.forEach((col) => {
            if (col === "meta_data") {
                columns.push({
                    field: col,
                    headerName: COLUMNS_VERBOSE_NAMES[col],
                    width: WIDTH_FIELDS[col] ? WIDTH_FIELDS[col] : 150,
                    renderCell: (params) => {
                        return printObject(params.value);
                    },
                });
            } else if (col === "wastes") {
                columns.push({
                    field: col,
                    headerName: COLUMNS_VERBOSE_NAMES[col],
                    width: WIDTH_FIELDS[col] ? WIDTH_FIELDS[col] : 150,
                    renderCell: (params) => {
                        return printArray(params.value);
                    },
                });
            } else {
                columns.push({
                    field: col,
                    headerName: COLUMNS_VERBOSE_NAMES[col],
                    type: "",
                    valueOptions: [],
                    width: WIDTH_FIELDS[col] ? WIDTH_FIELDS[col] : 150,
                });
            }
        });
    }

    const onDeleteClick = (id) => {
        setErrorState(undefined);
        setQueryMessage(undefined);
        if (
            window.confirm("Êtes-vous sûr(e) de vouloir supprimer cet acteur ?")
        ) {
            fetch(config.apiLink + "actor/" + id, {
                headers: {
                    Authorization: "Bearer " + token,
                },
                method: "DELETE",
            }).then(() => {
                setErrorState("success");
                setQueryMessage("Suppression réussie !");
                getActors();
            });
        } else {
            console.warn("Abort deletion of id " + id);
            setErrorState("warning");
            setQueryMessage("Suppression annulée !");
        }
    };

    columns.push({
        field: "action",
        headerName: "Actions",
        sortable: false,
        width: 150,
        renderCell: (params) => {
            return (
                <div>
                    <Link to={"/acteur/" + params.id + "/modifier"}>
                        <button className="pure-button pure-button-primary">
                            Modifier
                        </button>
                    </Link>
                    <button
                        className="pure-button button-error"
                        onClick={() => onDeleteClick(params.id)}
                    >
                        Supprimer
                    </button>
                </div>
            );
        },
    });

    let otherData =
        actors?.length > 0
            ? {
                  columns: columns,
                  rows: actors,
                  getRowId: (row) => {
                      return row.acteur_id;
                  },
              }
            : {
                  columns: [],
                  rows: [],
              };

    return (
        <div style={{ height: "800px", width: "100%" }}>
            <p>
                <Link to="/">Retour à la page principale</Link>
            </p>
            <p>
                <Link to={"/acteur/ajouter"}>
                    <button className="pure-button button-success">
                        Ajouter un nouvel acteur
                    </button>
                </Link>
            </p>
            <DataGrid
                {...otherData}
                experimentalFeatures={{ newEditingApi: true }}
                components={{ Toolbar: GridToolbar }}
                pageSize={50}
                rowsPerPageOptions={[50]}
                checkboxSelection
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                getRowHeight={() => "auto"}
            />
            <ModalMessage message={queryMessage} mode={errorState} />
        </div>
    );
}
