import React, { createRef } from "react";
import { Link } from "react-router-dom";

import { EditableParam, EditableParamSelectable } from "../utils/common.js";
import config from "../settings.js";

/**
 * Display a resource through resource type, wastes linked, the actor linked,
 * a link and an optional text to display with the link. The module also allows
 * to edit the fields.
 *
 * @param {Object} data object containg resource related data.
 * @param {Array} wastes the list of wastes that can be linked to current resource.
 * @param {callable} editCallback called when one of the fields is edited.
 * @param {callable} saveCallback called when trying to save current changes
 */
function Resource(props) {
    const changeValue = (propsName, newVal) => {
        let data_ = JSON.parse(JSON.stringify(props.data));
        data_[propsName] = newVal;
        props.editCallback(data_);
    };

    const removeCurrentResource = (keys) => {
        let validateDeletion = window.confirm(
            "Voulez-vous supprimer cette ressource ?"
        );
        if (validateDeletion) {
            props.deleteCallback();
        }
    };

    const changeWastes = (newValues) => {
        let data_ = JSON.parse(JSON.stringify(props.data));
        data_.dechets = [];
        data_.dechets_noms = [];
        newValues.forEach((e) => {
            data_.dechets.push(e.value);
            data_.dechets_noms.push(e.label);
        });
        props.editCallback(data_);
    };

    return (
        <div className="resource-element" id={"ressource_" + props.pageId}>
            <EditableParam
                editCallback={(e) => changeValue("type_ressource", e)}
                saveCallback={() => props.saveCallback(props.data.ressource_id)}
                name="Type de ressource"
                value={props.data.type_ressource}
            />
            <EditableParamSelectable
                inputData={props.wastes}
                idKey="dechet_id"
                labelKey="nom"
                hideId={true}
                name="Déchets"
                currentSelection={props.data.dechets}
                currentSelectionNames={props.data.dechets_noms}
                editCallback={changeWastes}
                saveCallback={() => props.saveCallback(props.data.ressource_id)}
            />
            <EditableParam
                editCallback={(e) => changeValue("organisme", e)}
                saveCallback={() => props.saveCallback(props.data.ressource_id)}
                name="Organisme"
                value={props.data.organisme}
            />
            <EditableParam
                editCallback={(e) => changeValue("lien", e)}
                saveCallback={() => props.saveCallback(props.data.ressource_id)}
                name="Lien"
                value={props.data.lien}
                link={true}
            />
            <EditableParam
                editCallback={(e) => changeValue("texte_lien", e)}
                saveCallback={() => props.saveCallback(props.data.ressource_id)}
                name="Texte de lien"
                value={props.data.texte_lien}
            />
            <div className="resource-toolbar">
                <button
                    className="pure-button button-tiny button-error"
                    onClick={() =>
                        removeCurrentResource(props.data.ressource_id)
                    }
                >
                    Supprimer
                </button>
            </div>
        </div>
    );
}

class AdminResources extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resources: [],
            scrollToBottom: false,
        };

        this.saveResources = this.saveResources.bind(this);
        this.messagesEnd = createRef();
    }

    componentDidMount() {
        // on récupère les données pour remplir les formulaires
        this.getResources();
        document.title = "QDQS - Gestion des ressources";
    }

    componentDidUpdate() {
        if (this.state.scrollToBottom) {
            this.setState({ scrollToBottom: false });
            this.scrollToBottom();
        }
    }

    getResources() {
        // récupère les données auprès de l'API
        fetch(config.apiLink + "resources/", {
            headers: {
                Authorization: "Bearer " + this.props.token,
            },
        })
            .then((response) => response.json())
            .then((json) => {
                this.setState({
                    resources: json["resources"],
                    wastes: json["wastes"],
                });
            })
            .catch((e) => {
                this.setState({
                    error: "Impossible de récupérer les données. Contactez l'administrateur !",
                });
            });
    }

    changeResource(id, newVal) {
        let resources = JSON.parse(JSON.stringify(this.state.resources));
        resources[id] = newVal;
        this.setState({
            resources: resources,
        });
    }

    deleteResources(id) {
        const resource = this.state.resources?.[id];
        if (!resource) return;
        if (!resource.ressource_id) {
            this.setState({
                resources: this.state.resources.filter(
                    (_, index) => index !== id
                ),
            });
            return;
        }
        const resourceId = resource.ressource_id;
        fetch(config.apiLink + "resources/update/" + resourceId + "/", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.token,
            },
        })
            .then((response) => response.json())
            .then((json) => {
                this.getResources();
            })
            .catch((e) => {
                console.log(e);
            });
    }

    saveResources(arrayId, resourceId) {
        fetch(config.apiLink + "resources/update/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.token,
            },
            body: JSON.stringify(this.state.resources[arrayId]),
        })
            .then((response) => response.json())
            .then((json) => {
                this.getResources();
            })
            .catch((e) => {
                console.log(e);
            });
    }

    scrollToBottom() {
        this.messagesEnd.current?.scrollIntoView({ behavior: "smooth" });
    }

    addResource() {
        this.setState({
            resources: [
                ...this.state.resources,
                {
                    correspondance: null,
                    dechets: [],
                    dechets_noms: [],
                    lien: "",
                    organisme: "",
                    texte_lien: "",
                    type_ressource: "",
                },
            ],
            scrollToBottom: true,
        });
    }

    render() {
        let resources = [];
        if (!this.state.resources) {
            return (
                <div>
                    <p>
                        <Link to="/">Retour à la page d'accueil</Link>
                    </p>
                    <p>Aucune ressource actuellement disponible.</p>
                </div>
            );
        }

        this.state.resources.forEach((resource, i) => {
            resources.push(
                <Resource
                    editCallback={(v) => this.changeResource(i, v)}
                    saveCallback={(v) => this.saveResources(i, v)}
                    deleteCallback={(v) => this.deleteResources(i, v)}
                    wastes={this.state.wastes}
                    data={resource}
                    pageId={i}
                />
            );
        });

        return (
            <div>
                <p>
                    <Link to="/">Retour à la page d'accueil</Link>
                </p>
                <p>
                    <button
                        className="pure-button button-success"
                        onClick={() => this.addResource()}
                    >
                        Ajouter une ressource
                    </button>
                </p>
                {resources}
                <div ref={this.messagesEnd}></div>
            </div>
        );
    }
}

export default AdminResources;
