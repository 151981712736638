import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import config from "../settings.js";

// token={token}
//                         removeToken={removeToken}
//                         setToken={setToken}
export default function NavBar({ token, removeToken, setToken }) {
    const navigate = useNavigate();

    /**
     * Affiche la barre de navigation
     */
    const [password, setPassword] = useState("");
    const [login, setLogin] = useState("");

    // prompt for password for admin
    const testPassword = (e) => {
        e.preventDefault();
        fetch(config.apiLink + "login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "same-origin",
            mode: "cors",
            body: JSON.stringify({ password, email: login }),
        })
            .then((response) => response.json())
            .then((json) => {
                setToken(json["access_token"]);
                navigate("/qdqs_admin/", { replace: true });
            });
    };

    if (token) {
        return (
            <p>
                <Link to="tests/">Tests sur la base</Link>
                {" "}
                - <Link to="ressources/">Gestion des ressources</Link>-{" "}
                {/* <Link to="dechets/">Gestion des déchets</Link>-{" "} */}
                <Link to="acteurs/">Gestion des prestataires</Link>-{" "}
                <Link to="maj/">Lancer la mise à jour des données</Link>-{" "}
                <a href="#logout" onClick={() => removeToken()}>
                    Se déconnecter
                </a>
            </p>
        );
    } else {
        return (
            <p>
                <form method="POST" onSubmit={(e) => testPassword(e)}>
                    <input
                        type="text"
                        name="login"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                    />
                    <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input type="submit" value="Se connecter" />
                </form>
            </p>
        );
    }
}
