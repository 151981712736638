import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import config from "../settings";

/**
 * List existing data updates, their creation date and the first characters
 * of their key.
 *
 * @param {Array} data the array containing updates data
 */
function DataUpdatesList(props) {
    // if we have at least one list
    if (props.data && Array.isArray(props.data) && props.data.length > 0) {
        let output = [];
        props.data.forEach((update) => {
            output.push(
                <li key={"update_list_" + update.update_id}>
                    #{update.update_id} (lancée le {update.update_date}) avec la
                    clé {update.update_key}
                </li>
            );
        });
        return <ul>{output}</ul>;
    } else {
        return <p>Aucune mise à jour actuellement.</p>;
    }
}

/**
 * Allow to select an actor and an update from existing elements and to generate
 * an example key and an URL to allow editing data.
 *
 * The key is generated through a call to the API.
 * @param {Array} actors the list of actors
 * @param {Array} updates the list of existing updates
 */
function DataUpdateKeyGenerator(props) {
    // selections
    const [actorSelection, selectActor] = useState(0);
    const [updateSelection, selectUpdate] = useState(0);
    // resulting generated key
    const [generatedKey, setGeneratedKey] = useState("");

    // list for final display of select tools
    let actorsList = [],
        updatesList = [];

    /**
     * Save new selection of either actor or update
     * @param {event} e the event triggered by changing selected option
     * @param {string} type type of change (either update or actor)
     */
    const handleChange = (e, type) => {
        if (type === "update") {
            selectUpdate(e.target.value);
        } else if (type === "actor") {
            selectActor(e.target.value);
        }
    };

    /**
     * Call the API to retrieve a key corresponding to current parameters. This
     * key could have been sent by mail to the actor related to the data studied.
     */
    const generateKey = (e) => {
        // we do not want to submit the form directly
        e.preventDefault();
        // calling the API requires both actor and update otherwise we cannot
        // generate a key.
        if (actorSelection !== 0 && updateSelection !== 0) {
            const params = {
                actor: actorSelection,
                update: updateSelection,
            };
            fetch(config.apiLink + "data/update/generate/key/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + props.token,
                },

                credentials: "same-origin",
                mode: "cors",
                body: JSON.stringify(params),
            })
                .then((response) => response.json())
                .then((json) => {
                    setGeneratedKey(json.key);
                });
        }
        return false;
    };

    // create the selection inputs
    props.actors.forEach((actor) => {
        actorsList.push(
            <option
                key={"actor_selection_option_" + actor.acteur_id}
                value={actor.acteur_id}
            >
                {actor.nom}
            </option>
        );
    });
    props.updates.forEach((update) => {
        updatesList.push(
            <option
                key={"update_selection_option_" + update.update_id}
                value={update.update_id}
            >
                {update.update_id} - {update.update_date}
            </option>
        );
    });

    return (
        <div>
            <form className="pure-form">
                <p>
                    <label htmlFor="actor_selection">Acteur : </label>
                    <select
                        onChange={(e) => handleChange(e, "actor")}
                        htmlFor="actor_selection"
                        defaultValue="0"
                    >
                        <option disabled value="0">
                            Sélectionner un acteur
                        </option>
                        {actorsList}
                    </select>
                </p>
                <p>
                    <label htmlFor="update_selection">Campagne : </label>
                    <select
                        onChange={(e) => handleChange(e, "update")}
                        name="update_selection"
                        defaultValue="0"
                    >
                        <option disabled value="0">
                            Sélectionner une campagne
                        </option>
                        {updatesList}
                    </select>
                </p>
                <p>
                    <button
                        className={
                            "pure-button " +
                            (actorSelection !== 0 && updateSelection !== 0
                                ? "pure-button-primary"
                                : "")
                        }
                        onClick={generateKey}
                    >
                        Générer la clé
                    </button>
                </p>
                {generatedKey ? (
                    <>
                        <p>
                            Clé pour l'acteur : <em>{generatedKey}</em>
                        </p>
                        <p>
                            <Link
                                to={`/mise-a-jour/${updateSelection}/${actorSelection}/${generatedKey}`}
                            >
                                Lien d'édition
                            </Link>{" "}
                            pour effectuer la mise à jour pour cet acteur
                        </p>
                    </>
                ) : (
                    ""
                )}
            </form>
        </div>
    );
}

/**
 * Component to handle Data update from an administrative point of view.
 */
export default function DataUpdate({ token }) {
    const [gotData, setDataStatus] = useState(false);
    // updates and actors lists retrieved from the API
    const [updatesList, setUpdatesList] = useState([]);
    const [actors, setActors] = useState([]);

    /**
     * Retrieve data if not already available.
     */
    useEffect(() => {
        // if we need to update current state (when mounting or when triggered by
        // changing gotData value).
        if (!gotData) {
            // we retrieve both data
            // we don't need them at the same time so we just launch both queries
            fetch(config.apiLink + "data/update/list/", {
                headers: {
                    Authorization: "Bearer " + token,
                },

                method: "GET",
                credentials: "same-origin",
                mode: "cors",
            })
                .then((response) => response.json())
                .then((json) => {
                    setUpdatesList(json.updates ?? []);
                    setDataStatus(true);
                });
            fetch(config.apiLink + "actors/", {
                headers: {
                    Authorization: "Bearer " + token,
                },

                method: "GET",
                credentials: "same-origin",
                mode: "cors",
            })
                .then((response) => response.json())
                .then((json) => {
                    // we sort actors by name to avoid a complete mess inside
                    // actors select input.
                    // cf. https://www.scaler.com/topics/javascript-sort-an-array-of-objects/
                    setActors(
                        json.actors
                            ? json.actors.sort((a, b) => {
                                  const nameA = a.nom.toUpperCase();
                                  const nameB = b.nom.toUpperCase();
                                  return nameA.localeCompare(nameB);
                              })
                            : []
                    );
                    setDataStatus(true);
                });
        }
    });

    /**
     * Launch a new update and generate a reference key through the API.
     *
     * After the update has been launched, we reset data status to call again
     * the API through useEffect.
     */
    const launchDataUpdate = () => {
        fetch(config.apiLink + "data/update/launch/", {
            headers: {
                Authorization: "Bearer " + token,
            },

            method: "GET",
            credentials: "same-origin",
            mode: "cors",
        })
            .then((response) => response.json())
            .then((json) => {
                setDataStatus(false);
            });
    };

    /**
     * Show the status of all actors contacted through last campaign.
     */
    const showStatusLastCampaign = () => {};

    return (
        <div>
            <p>
                <Link to="/">Retour à la page principale</Link>
            </p>

            <h1>Gestion de la mise à jour des données</h1>
            <p>Plusieurs actions sont possibles :</p>
            <p>
                <button
                    className="pure-button pure-button-primary"
                    onClick={launchDataUpdate}
                >
                    Lancer une mise à jour de données
                </button>
            </p>
            <p>
                <button
                    className="pure-button pure-button-primary"
                    onClick={showStatusLastCampaign}
                >
                    Regarder l'avancement des actions des acteurs contactés par
                    la dernière campagne
                </button>
            </p>
            <p>
                <button className="pure-button">
                    Gérer les acteurs n'ayant pas de points de contact
                </button>
            </p>

            <h2>Mises à jour</h2>
            <h3>Liste des mises à jour</h3>
            <DataUpdatesList data={updatesList} />
            <h3>Générer une clé</h3>
            <DataUpdateKeyGenerator
                updates={updatesList}
                actors={actors}
                token={token}
            />
        </div>
    );
}
