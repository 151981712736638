import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import NavBar from "./NavBar";
import useToken from "../utils/useToken";

export default function AdminMainPage({ token, removeToken, setToken }) {
    return (
        <div>
            <NavBar
                token={token}
                removeToken={removeToken}
                setToken={setToken}
            />
            <Outlet />
        </div>
    );
}
