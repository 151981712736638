import React from "react";
import { Link, Outlet } from "react-router-dom";

export default function Tests(props) {
    return (
        <div>
            <h2>Outils disponibles</h2>
            <p>
                Vous pouvez vérifier la liste des
                {" "}<Link to="email-manquant/">
                    installations sans adresse mail disponible
                </Link>, celle des
                {" "}<Link to="website-manquant/">
                    installations sans site web
                </Link>{" "}
                ou bien
                {" "}<Link to="tester-sites-web/">
                    tester les sites webs des installations
                </Link>
            </p>
            <Outlet />
        </div>
    );
}
