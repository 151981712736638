import React, { useEffect, useState } from "react";
import Select from "react-select";

/**
 * Component used to provide for a single editable parameter display.
 *
 * By default, the value is displayed, alongside field label if provided.
 * When clicking on the value, a text field is displayed to allow editing
 * current value. One button can cancel and reset to initial value whereas
 * another button submits edited value to be saved.
 *
 * @param {mixed} value current param value
 * @param {callable} editCallback function called when value is changed
 * @param {callable} saveCallback function called when changes are submitted
 * @param {str} name (optional) current field name
 */
export function EditableParam(props) {
    const [isEditing, setEditing] = useState(false);
    const [initialValue, setInitialValue] = useState(props.value);

    /**
     * Change current value through parent editCallback.
     * @param {mixed} e event triggered by change
     */
    const changeValue = (e) => {
        props.editCallback(e.target.value);
    };

    /**
     * Persist the value through parent saveCallback and leave editing mode.
     */
    const sendValue = (e) => {
        e.preventDefault();
        if (props.saveCallback) {
            props.saveCallback();
        }
        setInitialValue(props.value);
        setEditing(false);
    };

    /**
     * Cancel current changes and reset value to initial value. Use editCallback
     * to tell parent object to reset also its value. Leave editing mode.
     */
    const cancel = (e) => {
        e.preventDefault();
        props.editCallback(initialValue);
        setEditing(false);
    };

    let display = "";
    if (isEditing || props.forceEdition) {
        display = (
            <span>
                <input
                    type="text"
                    value={props.value}
                    onChange={(e) => changeValue(e)}
                />
                <span
                    className={props.cssButtonsClass ?? "css-buttons-editable"}
                >
                    <button
                        className="pure-button button-small"
                        onClick={(e) => cancel(e)}
                    >
                        {props.cancelLabel ?? "Annuler"}
                    </button>
                    {!props.forceEdition ? (
                        <button
                            className="pure-button button-small pure-button-primary"
                            onClick={(e) => sendValue(e)}
                        >
                            {props.sendLabel ?? "Envoyer"}
                        </button>
                    ) : (
                        ""
                    )}
                    {props.deletionCallback && (
                        <button
                            className="pure-button button-small button-error"
                            onClick={(e) => props.deletionCallback(e)}
                        >
                            {props.deletionLabel ?? "Supprimer"}
                        </button>
                    )}
                </span>
            </span>
        );
    } else {
        display = (
            <span onClick={(e) => setEditing(true)}>
                {props.value && props.value !== "" ? (
                    props.value
                ) : (
                    <em>Non rempli</em>
                )}
                <img src="/edit.svg" className="edit-icon" />
            </span>
        );
    }
    let label = props.name ? props.name + " : " : "";
    return (
        <span className={props.className ?? ""}>
            {!props.noLabel && (
                <label htmlFor="">
                    <strong>{label}</strong>
                </label>
            )}
            {display}
        </span>
    );
}

/**
 * Component used to provide for a selectionable and editable parameter display.
 *
 * By default, the values are displayed, alongside field label if provided.
 * When clicking on the values, a (single or multi) select field is displayed
 * to allow editing current values. One button can cancel and reset to initial
 * values whereas another button submits edited value to be saved.
 *
 * @param {Array} inputData input data used to fill selection field
 * @param {mixed} currentSelection current selection IDs between input data
 * @param {mixed} currentSelectionNames current selection names associated with IDs
 * @param {callable} editCallback function called when values are changed
 * @param {callable} saveCallback function called when changes are submitted
 * @param {string} idKey (optional, default: "id") key to use in input data objects to access ID
 * @param {string} labelKey (optional, default: "name") key to use in input data objects to access name displayed
 * @param {string} name (optional) current field name
 * @param {boolean} withoutGroupedOptions (optional, default: false) group or do not group options
 * @param {boolean} singleSelection (optional, default: false) restrict to a single selection field instead of multi-select.
 */
export function EditableParamSelectable(props) {
    const [isEditing, setEditing] = useState(false);
    const [initialSelection, setInitialSelection] = useState(
        props.currentSelection
    );

    /**
     * Change the single or multi selection for current objects through the
     * parent callbacks given.
     * @param {mixed} selectedOptions an array or an object containing
     * current selected option
     */
    const changeSelection = (selectedOptions) => {
        props.editCallback(selectedOptions);
    };

    /**
     * Persist values through parent saveCallback and leave editing mode.
     */
    const sendValue = (e) => {
        e.preventDefault();
        props.saveCallback();
        setEditing(false);
    };

    /**
     * Lookup a label from an ID inside inputData array.
     * @param {int} id reference of lookup object
     * @returns the name corresponding to given ID
     */
    const getLabelById = (id) => {
        const found = props.inputData.find((element) => {
            return element[props.idKey ?? "id"] === id;
        });
        if (found) {
            return found[props.labelKey ?? "name"];
        } else {
            return id;
        }
    };

    /**
     * Cancel current changes and reset selection to initial value(s). Use editCallback
     * to tell parent object to reset also its value(s). Leave editing mode.
     */
    const cancel = (e) => {
        e.preventDefault();
        let options = [];
        // we either have a multiple selection
        if (Array.isArray(initialSelection)) {
            initialSelection.forEach((element) => {
                options.push({
                    value: element,
                    label: getLabelById(element),
                });
            });
        }
        // or a single value
        else {
            options = {
                value: initialSelection,
                label: getLabelById(initialSelection),
            };
        }
        // we update parent object
        changeSelection(options);
        setEditing(false);
    };

    // if we are editing
    if (isEditing || props.forceEdition) {
        const options = [],
            optionsByCategory = {},
            optionsSelected = [],
            customStyles = {
                container: (provided, state) => ({
                    ...provided,
                    width: "450px",
                }),
            };

        // we convert the input data list into a list of objects
        // that can be read by Select library
        if (props.inputData && Array.isArray(props.inputData)) {
            props.inputData.forEach((element) => {
                let option = {
                    value: element[props.idKey ?? "id"],
                    label: element[props.labelKey ?? "name"],
                };
                if (!optionsByCategory[element.category_name]) {
                    optionsByCategory[element.category_name] = [];
                }
                optionsByCategory[element.category_name].push(option);
                options.push(option);

                // if we have multiple selection
                if (
                    Array.isArray(props.currentSelection) &&
                    props.currentSelection.includes(
                        element[props.idKey ?? "id"]
                    )
                ) {
                    optionsSelected.push(option);
                } else if (
                    props.currentSelection === element[props.idKey ?? "id"]
                ) {
                    optionsSelected.push(option);
                }
            });
        }

        let groupedOptions = [];
        // if we do not need grouped options, we just give options raw list
        if (props.withoutGroupedOptions) {
            groupedOptions = options;
        }
        // otherwise, if we need options by category, we group them
        // cf. https://react-select.com/home
        else {
            for (const category in optionsByCategory) {
                if (Object.hasOwnProperty.call(optionsByCategory, category)) {
                    const elements = optionsByCategory[category];
                    groupedOptions.push({
                        label: category,
                        options: elements,
                    });
                }
            }
        }

        return (
            <div>
                <label htmlFor="">
                    <strong>{props.name ?? "Donnée"} : </strong>
                </label>
                <Select
                    isMulti={!props.singleSelection}
                    name="colors"
                    placeholder={props.name ?? "Déchets associés"}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={groupedOptions}
                    value={optionsSelected}
                    onChange={changeSelection}
                    styles={customStyles}
                />
                {props.saveCallback && (
                    <div
                        className={
                            props.cssButtonsClass ?? "css-buttons-editable"
                        }
                    >
                        <button
                            className="pure-button button-small"
                            onClick={(e) => cancel(e)}
                        >
                            {props.cancelLabel ?? "Annuler"}
                        </button>
                        <button
                            className="pure-button button-small pure-button-primary"
                            onClick={(e) => sendValue(e)}
                        >
                            {props.sendLabel ?? "Envoyer"}
                        </button>
                    </div>
                )}
            </div>
        );
    }
    // otherwise
    else {
        let data = [];
        // if anything was selected previously, we display it
        // it can either be a list of multiple elements
        if (
            props.currentSelection &&
            Array.isArray(props.currentSelection) &&
            props.currentSelection.length > 0 &&
            props.currentSelection[0] !== null
        ) {
            props.currentSelection.forEach((element, id) => {
                if (element !== null) {
                    data.push(
                        <span>
                            {!props.hideId ? <>#{element} - </> : ""}
                            {props.currentSelectionNames[id]}
                        </span>
                    );
                }
            });
        }
        // or just a single element
        else if (
            !Array.isArray(props.currentSelection) &&
            props.currentSelection !== undefined &&
            props.currentSelection !== false
        ) {
            data.push(
                <span>
                    {!props.hideId ? <>#{props.currentSelection} - </> : ""}
                    {props.currentSelectionNames}
                </span>
            );
        }

        let label = props.name ? props.name + " : " : "";

        return (
            <div onClick={(e) => setEditing(true)}>
                <label htmlFor="">
                    <strong>{label}</strong>
                </label>
                {/* {props.data.correspondance !== null && data.length === 0 ? (<p>{props.data.correspondance}</p>) : ""} */}
                {data.length > 0 ? (
                    <>
                        {data.reduce((r, i) => (
                            <>
                                {r}, {i}
                            </>
                        ))}
                    </>
                ) : (
                    "Aucune donnée pour l'instant"
                )}
            </div>
        );
    }
}
/**
 * Convert a string to a normalized string lowercase without unusual characters.
 *
 * Thanks to https://stackoverflow.com/a/37511463
 * @param {str} str input string
 * @returns string without unusual characters.
 */
const normalizeString = (str) => {
    return str
        .toLowerCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");
};

/**
 * Filter React Table rows based on a filter value. Fix the case-sensitive default
 * filter.
 * cf. https://github.com/TanStack/table/issues/335#issuecomment-308655776
 * @param {object} filter the filter object from the input text
 * @param {object} row the row to test
 * @returns whether a row contains the filter value
 */
export const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;

    if (row[id] !== null) {
        return row[id] !== undefined
            ? String(normalizeString(row[id])).includes(
                  normalizeString(filter.value)
              )
            : true;
    }
};

/**
 * Modal window
 */

/**
 * This component is used to display an alert message at the top of the application
 */
export function ModalMessage({ message, mode }) {
    const [_message, setMessage] = useState(message);
    const [_mode, setMode] = useState(mode ?? "success");

    useEffect(() => {
        setMessage(message);
        setMode(mode);
        setTimeout(() => {
            setMessage("");
            setMode("success");
        }, 5000);
    }, [message, mode]);

    let classNameMessage = "alert-" + _mode;
    if (!_message || _message === "") return null;
    else {
        return (
            <div className={"messages-msg " + classNameMessage}>{_message}</div>
        );
    }
}
