import React from "react";
import slugify from "slugify";
import iconv from "iconv-lite";
// import config from './settings.js';

import Map from "./Map";
import FilterableTable, { SelectColumnFilter } from "../utils/FilterableTable";

function ResultingResources(props) {
    let output = [];
    if (Array.isArray(props.resources)) {
        props.resources.forEach((resource) => {
            output.push(
                <li key={"resource-" + resource.ressource_id}>
                    <a href={resource.lien} target="_blank" rel="noreferrer">
                        {resource.texte_lien ?? resource.organisme}
                        {resource.texte_lien
                            ? " (" + resource.organisme + ")"
                            : ""}
                    </a>
                </li>
            );
        });
    }
    if (output.length > 0) {
        return (
            <div className="useful-resources">
                <h3>Les références à consulter</h3>
                <ul>{output}</ul>
            </div>
        );
    } else {
        return "";
    }
}

function TableResults({
    input,
    showTreatment,
    callbackDetails,
    callbackRowSelection,
    departements,
}) {
    const data = React.useMemo(() => input, [input]);
    let initialState = {};

    if (!showTreatment) {
        initialState = {
            hiddenColumns: ["type_traitement"],
        };
    }

    const columns = React.useMemo(
        () => [
            // {
            //     Header: "Type de service",
            //     accessor: "type_service",
            //     Filter: SelectColumnFilter,
            //     filter: "includes",
            // },
            {
                Header: "Type de traitement",
                accessor: "type_traitement",
                id: "type_traitement",
                Filter: SelectColumnFilter,
                filter: "includes",
            },
            {
                Header: "Nom de l'acteur",
                accessor: "nom_acteur",
                filter: "fuzzyText",
            },
            {
                Header: "Département",
                filter: "fuzzyText",
                Cell: (props) => {
                    return (
                        <p>
                            {
                                departements[
                                    props.row.original.cp.substring(0, 2)
                                ]
                            }
                        </p>
                    );
                },
            },
            {
                Header: "Commune",
                accessor: "city",
                filter: "fuzzyText",
                Cell: (props) => {
                    return (
                        <p>
                            {props.row.original.city}
                            <br />({props.row.original.cp})
                        </p>
                    );
                },
            },
            {
                Header: "Type de source",
                accessor: "type_source",
                filter: "fuzzyText",
            },
            {
                Header: "Détails",
                // accessor: "cp",
                Cell: (props) => {
                    return (
                        <button
                            className="details"
                            onClick={() =>
                                callbackDetails(
                                    props.row.original.actor_type_key,
                                    props.row.original.acteur_id,
                                    props.row.original.type_source
                                )
                            }
                        >
                            Détails
                        </button>
                    );
                },
            },
        ],

        [callbackDetails]
    );

    return (
        <FilterableTable
            columns={columns}
            data={data}
            initialState={initialState}
            rowClickCallback={callbackRowSelection}
        />
    );
}

class Results extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeActor: -1,
            activeTab: this.getFirstNonEmptyResultGroup(),
            hiddenElementsId: [],
        };

        this.callbackRowSelection = this.callbackRowSelection.bind(this);
        this.updateHiddenElements = this.updateHiddenElements.bind(this);
    }

    getFirstNonEmptyResultGroup() {
        let firstKeyNonEmpty = -1;
        for (const key in this.props.data) {
            if (this.props.data[key].length > 0) {
                const actorTypeDetail = this.verboseActorType(key);
                firstKeyNonEmpty = actorTypeDetail.nom;
                break;
            }
        }
        return firstKeyNonEmpty;
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(Object.keys(this.props.data)) !==
            JSON.stringify(Object.keys(prevProps.data))
        ) {
            this.setState({
                activeActor: -1,
                activeTab: this.getFirstNonEmptyResultGroup(),
            });
        }
    }

    verboseActorType(id) {
        const correspondingTypes = this.props.actorsTypes.filter(
            (type) => parseInt(type.acteur_type_id, 10) === parseInt(id, 10)
        );

        if (correspondingTypes.length > 0) {
            return {
                nom: correspondingTypes[0]["type_service"],
                details: correspondingTypes[0]["type_traitement"],
                logo: "/svg/" + correspondingTypes[0]["icone"],
            };
        } else {
            return {
                nom: "Type d'acteur inconnu",
                details: "",
                logo: "/svg/marker-red.svg",
            };
        }
    }

    callbackRowSelection(clickedActor) {
        this.setState({
            activeActor: clickedActor.acteur_id,
        });
    }

    updateHiddenElements(e) {
        if (e !== this.state.hiddenElementsId) {
            this.setState({
                hiddenElementsId: e,
            });
        }
    }

    changeTab(e) {
        if (e.target.dataset.tab) {
            this.setState({ activeTab: e.target.dataset.tab });
        }
    }

    exportResults(results) {
        // export results to csv
        const csvRows = [];
        const headers = [
            "type_service",
            "type_traitement",
            "nom_acteur",
            "nom",
            "city",
            "cp",
            "type_source",
        ];
        const niceHeaders = [
            "Type de service",
            "Type de traitement",
            "Acteur",
            "Nom du service",
            "Commune",
            "Code postal",
            "Source de la donnée",
        ];

        csvRows.push(niceHeaders.join(","));

        for (const row of results) {
            const values = headers.map((header, i) => {
                const escaped = ("" + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(","));
        }

        const csvData = iconv.encode(csvRows.join("\n"), "win1252");
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute(
            "download",
            "export_recherche_qdqs_" + new Date().toLocaleDateString() + ".csv"
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        let failMessages = "";

        if (this.props.failCodes) {
            failMessages = (
                <p className="error-message">{this.props.failCodes}</p>
            );
        }

        let resultsRender = "",
            allResults = {},
            allResultsLinearised = [],
            hasTreatmentDetails = {},
            resultsMap = "",
            resultsResources = "";

        if (Object.keys(this.props.data).length > 0) {
            for (const key in this.props.data) {
                const actorTypeKey = this.verboseActorType(key).nom;
                const elements = this.props.data[key];
                if (elements.length === 0) continue;

                if (!Object.keys(allResults).includes(actorTypeKey)) {
                    allResults[actorTypeKey] = [];
                    hasTreatmentDetails[actorTypeKey] = false;
                }
                elements.map((res) => {
                    if (
                        !this.state.hiddenElementsId.includes(
                            res.acteur_type_id.toString()
                        )
                    ) {
                        res["city"] = res.meta_data?.address.ville;
                        res["cp"] = res.meta_data?.address.cp;
                        res["actor_type_key"] = key;
                        res["specific_class"] =
                            this.state.activeActor === res.acteur_id
                                ? "actor-active"
                                : "";
                        if (
                            res.type_traitement &&
                            res.type_traitement !== undefined &&
                            res.type_traitement !== ""
                        ) {
                            hasTreatmentDetails[actorTypeKey] = true;
                        }
                        allResults[actorTypeKey].push(res);
                        allResultsLinearised.push({
                            type: actorTypeKey,
                            ...res,
                        });
                    }
                });
            }
            resultsMap = (
                <Map
                    pointsOfInterest={this.props.data}
                    centerCity={this.props.centerCity}
                    callbackDetails={this.props.callbackDetails}
                    callbackHiddenElements={this.updateHiddenElements}
                    getActorType={this.verboseActorType.bind(this)}
                    activeActor={this.state.activeActor}
                    radius={this.props.radius}
                />
            );
            resultsResources = (
                <ResultingResources resources={this.props.resources} />
            );

            if (
                allResults.length === 0 &&
                this.state.hiddenElementsId.length > 0
            ) {
                resultsRender = (
                    <p>
                        Pas de résultats visibles. Réactivez certaines
                        catégories dans la légende pour en afficher les
                        éléments.
                    </p>
                );
            } else if (
                allResults.length === 0 ||
                Object.keys(allResults).length === 0
            ) {
                failMessages = (
                    <div className="results-container">
                        <strong>Pas de résultats.</strong>
                    </div>
                );
                resultsRender = "";
                resultsMap = "";
            } else {
                resultsRender = [];
                resultsRender.push(
                    <div className="export-button">
                        <button
                            onClick={() =>
                                this.exportResults(allResultsLinearised)
                            }
                        >
                            Exporter les résultats
                        </button>
                    </div>
                );
                let alreadyRenderedTypes = [];
                for (const key in this.props.data) {
                    const actorTypeDetail = this.verboseActorType(key);
                    const actorTypeKey = actorTypeDetail.nom;
                    if (
                        alreadyRenderedTypes.includes(actorTypeKey) ||
                        !Object.keys(allResults).includes(actorTypeKey) ||
                        allResults[actorTypeKey].length === 0
                    ) {
                        continue;
                    }
                    const slugActorKey = slugify(actorTypeKey);
                    resultsRender.push(
                        <div
                            className="table-tabs-group"
                            key={"tab-header-" + slugActorKey}
                        >
                            <div
                                className={
                                    "table-tabs-item " +
                                    (this.state.activeTab === actorTypeKey
                                        ? "tab-active"
                                        : "")
                                }
                                id={"table-tabs-" + slugActorKey}
                                data-tab={actorTypeKey}
                                onClick={(e) => this.changeTab(e)}
                            >
                                <img
                                    src={actorTypeDetail["logo"]}
                                    alt=""
                                    width="27"
                                    data-tab={actorTypeKey}
                                />
                                {actorTypeKey}
                            </div>
                        </div>
                    );
                    alreadyRenderedTypes.push(actorTypeKey);
                }
                for (const actorTypeKey in allResults) {
                    const slugActorKey = slugify(actorTypeKey);
                    resultsRender.push(
                        <div
                            className={
                                this.state.activeTab === actorTypeKey
                                    ? "displayed_tab"
                                    : "hidden_tab"
                            }
                            id={"table-tabs-" + slugActorKey}
                            key={"tab-content-" + slugActorKey}
                        >
                            <TableResults
                                showTreatment={
                                    hasTreatmentDetails[actorTypeKey]
                                }
                                input={allResults[actorTypeKey]}
                                departements={this.props.departements}
                                callbackDetails={this.props.callbackDetails}
                                callbackRowSelection={this.callbackRowSelection}
                            />
                        </div>
                    );
                }
            }
        } else if (this.props.status) {
            resultsRender = <p>Requête en cours...</p>;
        } else {
            resultsRender = "";
        }

        return (
            <div>
                {resultsResources}
                {resultsMap}
                {failMessages}
                <div className="results-container">
                    <div>{resultsRender}</div>
                </div>
            </div>
        );
    }
}

export default Results;
