import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import config from "../settings.js";
import { DisplayWastesByCategories } from "../survey/UpdateEquipement.js";
import {
    EditableParam,
    EditableParamSelectable,
    ModalMessage,
} from "../utils/common.js";

export default function EditActor(props) {
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(false);
    const [queryMessage, setQueryMessage] = useState(undefined);
    const [errorState, setErrorState] = useState("success");

    const [actorTypes, setActorTypes] = useState([]);
    const [actorAdditionSelection, setActorAdditionSelection] = useState([]);
    const [newMetadataKeys, setNewMetadataKeys] = useState({});

    const [wastes, setWastes] = useState([]);
    const [selectedWastes, setSelectedWastes] = useState([]);
    const [wastesCategories, setWastesCategories] = useState([]);
    const [wasteAdditionSelection, setWasteAdditionSelection] =
        useState(undefined);
    const [wasteAdditionNames, setWasteAdditionNames] = useState(undefined);

    const isEditing = () => {
        return props.mode !== "addition";
    };

    let params = useParams();
    const actorId = isEditing() ? params.idacteur : undefined;

    useEffect(() => {
        if (data === undefined) {
            if (isEditing()) {
                // récupère les données auprès de l'API
                fetch(config.apiLink + "actor/" + actorId, {
                    headers: {
                        Authorization: "Bearer " + props.token,
                    },
                })
                    .then((response) => {
                        if (response.status === 404) {
                            setError("Acteur invalide ou en tout cas inconnu.");
                        } else {
                            return response.json();
                        }
                    })
                    .then((json) => {
                        setData(json["actor"]);
                        // we build selected wastes list based on actor data
                        const relevantWastes = json["actor"]?.wastes?.map(
                            (w) => w.dechet_id
                        );
                        setSelectedWastes(relevantWastes);
                        // we build selected actor types list based on actor data
                        setActorAdditionSelection(
                            json["actor"]?.actor_types_id ?? []
                        );
                        setError(false);
                    })
                    .catch((e) => {
                        setData([]);
                    });
            } else {
                setData({
                    nom: "",
                    contenu: "",
                    meta_data: {
                        address: {
                            cp: "",
                            rue: "",
                            ville: "",
                        },
                        email: "",
                        website: "",
                    },
                });
            }
            /**
             * Call API to retrieve wastes and wastes categories list.
             */
            // retrieve data from API
            fetch(config.apiLink + "wastes/", {
                headers: {
                    Authorization: "Bearer " + props.token,
                },
            })
                .then((response) => response.json())
                .then((json) => {
                    setWastes(json["wastes"] ?? []);
                    setWastesCategories(json["categories"] ?? []);
                })
                .catch((e) => {});
            /**
             * Call API to retrieve actor types
             */
            // retrieve data from API
            fetch(config.apiLink + "actors/types/", {
                headers: {
                    Authorization: "Bearer " + props.token,
                },
            })
                .then((response) => response.json())
                .then((json) => {
                    setActorTypes(json["types"] ?? []);
                })
                .catch((e) => {});
        }
    });

    const editField = (e, type) => {
        let _data = JSON.parse(JSON.stringify(data));
        _data[type] = e.target.value;
        setData(_data);
    };

    const removeWasteFromEquipment = (wasteId) => {
        setSelectedWastes(selectedWastes.filter((wId) => wId !== wasteId));
    };

    // Update waste list
    const changeWastes = (newValue) => {
        setWasteAdditionSelection(newValue.value);
    };

    const addWasteToEquipment = () => {
        if (wasteAdditionSelection) {
            let _selectedWastes = JSON.parse(JSON.stringify(selectedWastes));
            if (!_selectedWastes.includes(wasteAdditionSelection)) {
                setSelectedWastes([..._selectedWastes, wasteAdditionSelection]);
            }
        }
    };

    // Update waste list
    const changeActorTypes = (newValue) => {
        setActorAdditionSelection(newValue.map((v) => v.value));
    };

    const goThroughMetadata = (keys, currentObj) => {
        for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i];
            if (currentObj.hasOwnProperty(key)) {
                currentObj = currentObj[key];
            } else {
                console.warn("Not finding key " + key + " in object!");
                currentObj = undefined;
            }
        }
        const lastKey = keys[keys.length - 1];
        return { lastKey, currentObj };
    };

    const changeMetaDataValue = (keys, newValue) => {
        // we copy
        let rawObj = JSON.parse(JSON.stringify(data.meta_data));

        // we update the value
        const { lastKey, currentObj } = goThroughMetadata(keys, rawObj);
        currentObj[lastKey] = newValue;

        setData({
            ...data,
            meta_data: rawObj,
        });
    };

    const saveMetadataKeyChange = (keys) => {
        const newValue = newMetadataKeys[keys[keys.length - 1]];
        // we copy
        let rawObj = JSON.parse(JSON.stringify(data.meta_data));

        // we update the value
        const { lastKey, currentObj } = goThroughMetadata(keys, rawObj);

        if (lastKey !== newValue) {
            currentObj[newValue] = JSON.parse(
                JSON.stringify(currentObj[lastKey])
            );
            delete currentObj[lastKey];

            setData({
                ...data,
                meta_data: rawObj,
            });
        }
    };
    const changeMetaDataKey = (keys, newValue) => {
        setNewMetadataKeys({
            ...newMetadataKeys,
            [keys[keys.length - 1]]: newValue,
        });
    };

    const removeMetaDataKey = (keys) => {
        // we copy
        let rawObj = JSON.parse(JSON.stringify(data.meta_data));

        // we update the value
        const { lastKey, currentObj } = goThroughMetadata(keys, rawObj);
        delete currentObj[lastKey];

        setData({
            ...data,
            meta_data: rawObj,
        });
    };

    const printObject = (object, keys = []) => {
        let output = [];
        for (let key in object) {
            const value = object[key];
            if (typeof value === "object") {
                output.push(
                    <li key={key}>
                        <strong>
                            <EditableParam
                                saveCallback={() =>
                                    saveMetadataKeyChange(keys.concat([key]))
                                }
                                editCallback={(newValue) =>
                                    changeMetaDataKey(
                                        keys.concat([key]),
                                        newValue
                                    )
                                }
                                deletionCallback={() =>
                                    removeMetaDataKey(keys.concat([key]))
                                }
                                sendLabel={"Modifier"}
                                name="Clé"
                                value={newMetadataKeys[key] ?? key}
                                noLabel={true}
                            />{" "}
                            :{" "}
                        </strong>
                        {printObject(value, keys.concat([key]))}
                    </li>
                );
            } else {
                output.push(
                    <li key={key}>
                        <strong>
                            <EditableParam
                                saveCallback={() =>
                                    saveMetadataKeyChange(keys.concat([key]))
                                }
                                editCallback={(newValue) =>
                                    changeMetaDataKey(
                                        keys.concat([key]),
                                        newValue
                                    )
                                }
                                deletionCallback={() =>
                                    removeMetaDataKey(keys.concat([key]))
                                }
                                sendLabel={"Modifier"}
                                name="Clé"
                                value={newMetadataKeys[key] ?? key}
                                noLabel={true}
                            />{" "}
                            :{" "}
                        </strong>
                        <EditableParam
                            editCallback={(newValue) =>
                                changeMetaDataValue(
                                    keys.concat([key]),
                                    newValue
                                )
                            }
                            deletionCallback={() =>
                                removeMetaDataKey(keys.concat([key]))
                            }
                            sendLabel={"Modifier"}
                            noLabel={true}
                            name={key}
                            value={value}
                        />
                    </li>
                );
            }
        }
        return <ul className="meta_data_actor">{output}</ul>;
    };

    const isValidForm = () => {
        return (
            data.nom &&
            selectedWastes.length > 0 &&
            actorAdditionSelection.length > 0
        );
    };

    const saveForm = (e) => {
        e.preventDefault();
        setQueryMessage(undefined);
        setErrorState(undefined);
        if (!isValidForm()) {
            setQueryMessage(
                "Le formulaire est invalide ! Il faut au moins un nom, un type d'acteur et un déchet associé !"
            );
            setErrorState("error");
            return;
        }
        const url = isEditing() ? "actor/" + actorId : "add/actor/";
        const method = isEditing() ? "POST" : "PUT";
        const body = JSON.stringify({
            data,
            selectedWastes,
            actorsTypes: actorAdditionSelection,
        });
        fetch(config.apiLink + url, {
            body,
            method,
            credentials: "same-origin",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + props.token,
            },
        })
            .then(
                // on traite la réponse
                (response) => response.json()
            )
            .then((json) => {
                if (json?.update) {
                    setQueryMessage(
                        "La modification a été effectuée avec succès !"
                    );
                    setErrorState("success");
                } else if (json?.addition) {
                    setQueryMessage("L'ajout a été effectué avec succès !");
                    setErrorState("success");
                } else {
                    setQueryMessage("La modification n'a pas réussi !");
                    setErrorState("error");
                }
            });
    };

    if (error !== false) {
        return (
            <div>
                <p>
                    <Link to="/acteurs/">
                        Retour à la liste des prestataires
                    </Link>
                </p>
                <p>{error}</p>
            </div>
        );
    } else if (data !== undefined) {
        return (
            <div>
                <p>
                    <Link to="/acteurs/">
                        Retour à la liste des prestataires
                    </Link>
                </p>
                <h1>
                    {isEditing() ? (
                        <>Modifier l'acteur #{actorId}</>
                    ) : (
                        "Ajouter un nouvel acteur"
                    )}
                </h1>
                <form onSubmit={(e) => saveForm(e)}>
                    <div>
                        <label for="nom">
                            <strong>Nom :</strong>{" "}
                        </label>
                        <input
                            id="nom"
                            type="text"
                            value={data.nom}
                            onChange={(e) => editField(e, "nom")}
                        />
                    </div>
                    <div>
                        <label for="description">
                            <strong>Description : </strong>
                        </label>
                        <br />
                        <textarea
                            id="description"
                            className="content"
                            onChange={(e) => editField(e, "contenu")}
                        >
                            {data.contenu}
                        </textarea>
                    </div>
                    <div>
                        <EditableParamSelectable
                            inputData={actorTypes}
                            idKey="acteur_type_id"
                            labelKey="type_global_service"
                            hideId={true}
                            singleSelection={false}
                            forceEdition={true}
                            withoutGroupedOptions={true}
                            currentSelection={actorAdditionSelection}
                            editCallback={changeActorTypes}
                            name="Type de prestataire"
                        />
                    </div>
                    <h2>Déchets gérés au sein de cette activité</h2>
                    <DisplayWastesByCategories
                        wastes={wastes.filter((w) =>
                            selectedWastes.includes(w.dechet_id)
                        )}
                        wastesCategories={wastesCategories}
                        deleteCallback={removeWasteFromEquipment}
                    />
                    <div>
                        <label>Ajouter un déchet : </label>
                        <EditableParamSelectable
                            inputData={wastes}
                            idKey="dechet_id"
                            labelKey="nom"
                            hideId={true}
                            singleSelection={true}
                            forceEdition={true}
                            currentSelection={wasteAdditionSelection}
                            currentSelectionNames={wasteAdditionNames}
                            editCallback={changeWastes}
                            saveCallback={addWasteToEquipment}
                            sendLabel="Ajouter"
                            name="Déchet"
                        />
                    </div>
                    <h2>Méta-données</h2>
                    <div className="actor_edition">
                        {printObject(data.meta_data)}
                    </div>

                    <p>
                        <button
                            className="pure-button pure-button-primary"
                            type="submit"
                            onClick={(e) => saveForm(e)}
                        >
                            Enregistrer
                        </button>
                    </p>
                    <ModalMessage message={queryMessage} mode={errorState} />
                </form>
            </div>
        );
    } else {
        return (
            <div>
                <p>
                    <Link to="/acteurs/">
                        Retour à la liste des prestataires
                    </Link>
                </p>
            </div>
        );
    }
}
