import React from "react";

import config from "./settings.js";
import { SelectField } from "./MainPage.js";

const VERBOSE_RESPONSIBILITY = {
    omr: "En charge de la collecte des ordures ménagères",
    cs: "En charge de la collecte sélective",
    decheterie: "En charge de la collecte en déchèterie(s)",
};

class PublicMainPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cities: [],
            city: undefined,
            queryPending: false,
            results: [],
            error: "",
        };
    }

    componentDidMount() {
        document.title =
            "QDQS - Chercher des solutions pour mes déchets - Page pour les particuliers";

        // on récupère les données pour remplir les formulaires
        this.getCitiesList();
    }

    getCitiesList() {
        // récupère les données auprès de l'API
        fetch(config.apiLink + "cities/", {})
            .then((response) => response.json())
            .then((json) => {
                this.setState({
                    cities: json,
                    error: "",
                    warning: "",
                });
            })
            .catch((e) => {
                this.setState({
                    error: "Impossible de récupérer les données. Contactez l'administrateur !",
                });
            });
    }

    changeCity(newValue) {
        this.setState({
            city: newValue,
        });
    }

    lookupCityResults(e) {
        // on gère les cas où il manque des informations pour faire la requête
        if (!this.state.city) {
            this.setState({
                error: "Il manque votre choix de commune !",
            });
            return;
        }
        // on met à 0 les résultats et on affiche le fait qu'une requête est en cours.
        this.setState({ queryPending: true, results: [] });

        // constitution des paramètres de la requête
        const body = JSON.stringify({
            city_code: this.state.city,
        });
        fetch(config.apiLink + "search/city/", {
            body,
            method: "POST",
            credentials: "same-origin",
            mode: "cors",
        })
            .then(
                // on traite la réponse
                (response) => response.json()
            )
            .then((json) => {
                // on enregistre
                this.setState({
                    results: json,
                    queryPending: false,
                    error: "",
                });
            });
    }

    parseResults() {
        if (!this.state.results) {
            return "";
        }
        let output = [];
        for (const actor of this.state.results) {
            if (
                !actor.responsibilities ||
                actor.responsibilities.length === 0
            ) {
                continue;
            }
            let territorialCompetences = [];
            for (const responsibility of actor.responsibilities) {
                territorialCompetences.push(
                    <li>
                        {VERBOSE_RESPONSIBILITY?.[responsibility] ??
                            responsibility}
                    </li>
                );
            }
            output.push(
                <div>
                    <h3>{actor.name}</h3>
                    {actor.web_site && (
                        <h4>
                            Voir{" "}
                            <a
                                href={"https://" + actor.web_site}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {actor.web_site}
                            </a>
                        </h4>
                    )}
                    <ul>{territorialCompetences}</ul>
                </div>
            );
        }

        return output;
    }

    render() {
        let results = this.parseResults();
        // affichage d'un disclaimer quand il y a des erreurs
        let error = "";
        if (this.state.error !== "") {
            error = (
                <div className="error disclaimer-message">
                    <span>{this.state.error}</span>
                </div>
            );
        }

        return (
            <div>
                <h1>Pour un particulier</h1>

                <p>
                    Vous êtes un particulier et vous recherchez une solution
                    pour un type de déchet sur votre commune.
                </p>
                {error}
                <div>
                    <SelectField
                        data={this.state.cities}
                        name="communes"
                        defaultLabel="Choisissez une commune"
                        change={this.changeCity.bind(this)}
                        idKey="code_insee"
                        labelKey="nom_commune"
                    />
                </div>
                <p>
                    <button onClick={this.lookupCityResults.bind(this)}>
                        Rechercher
                    </button>
                </p>

                <div className="explanations">
                    <p>
                        Retrouvez, pour votre commune, l'ensemble des structures
                        responsables des différentes collectes (ordures
                        ménagères résiduelles, collecte sélective) et des
                        déchèteries.
                    </p>
                    <p>
                        Si vous cherchez un acteur qui pourrait vous rendre un
                        service hors de ces périmètres, reportez-vous au module
                        pour les <em>Professionnels</em>.
                    </p>
                </div>
                <div className="results-city-level">{results}</div>
            </div>
        );
    }
}

export default PublicMainPage;
