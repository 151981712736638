import { createPath } from "react-router-dom";

export function AccueilPros(props) {
    /**
     * Module d'affiche des conditions d'accueil des professionnels
     */
    if (!props.data || Object.entries(props.data).length === 0) {
        return "";
    }
    let listInfos = [];

    // Affichage de la liste des données
    for (const label in props.data) {
        if (Object.hasOwnProperty.call(props.data, label)) {
            const value = props.data[label];
            let displayV = value;
            // on calcule une valeur à afficher
            if (value === true) {
                displayV = "Oui";
            } else if (value === false) {
                displayV = "Non";
            } else if (value === "") {
                displayV = " - ";
            }
            listInfos.push(
                <li key={"accueil_pros_" + label}>
                    <strong>{label} : </strong>
                    {displayV}
                </li>
            );
        }
    }

    return (
        <div className="blockClassic">
            <h3>Accueil des professionnels</h3>
            <ul>{listInfos}</ul>
        </div>
    );
}

export function Agrements(props) {
    if (!props.data || Object.entries(props.data).length === 0) {
        return "";
    }
    let listInfos = [];

    // Affichage de la liste des données
    for (const label in props.data) {
        // On a bien un objet
        if (Object.hasOwnProperty.call(props.data, label)) {
            const value = props.data[label];

            // selon le booléen, on affiche Oui ou -
            let displayV = value;
            if (value === true) {
                displayV = "Oui";
            } else if (value === false) {
                displayV = " - ";
            }
            listInfos.push(
                <li key={"agrements_" + label}>
                    <strong>{label} : </strong>
                    {displayV}
                </li>
            );
        }
    }

    return (
        <div className="blockClassic">
            <h3>Agréments</h3>
            <ul>{listInfos}</ul>
        </div>
    );
}

export function Territoire(props) {
    if (!props.data || Object.entries(props.data).length === 0) {
        return "";
    }
    let listInfos = [];

    for (const label in props.data) {
        if (Object.hasOwnProperty.call(props.data, label)) {
            const value = props.data[label];
            listInfos.push(
                <li key={"territoire_" + label}>
                    <strong>{label} : </strong>
                    {value}
                </li>
            );
        }
    }

    return (
        <div className="blockClassic">
            <h3>Territoire</h3>
            <ul>{listInfos}</ul>
        </div>
    );
}

export function DechetsAcceptes(props) {
    if (!props.data || Object.entries(props.data).length === 0) {
        return "";
    }
    let listInfos = [];
    let sortedDechets = [];
    for (var dechet in props.data) {
        sortedDechets.push([dechet, props.data[dechet]]);
    }

    // On trie les déchets par nom
    sortedDechets = sortedDechets.sort((a, b) => {
        var x = a[1].toLowerCase();
        var y = b[1].toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
    });

    sortedDechets.forEach((key) => {
        listInfos.push(
            <div key={"dechets_acceptes_" + key[0]}>- {key[1]}</div>
        );
    });

    let avertissementDechetsPros =
        !props.dechetsPro && props.dechetsRefuses ? (
            <div>
                <br />
                <p>
                    <em>* : Refusés pour les professionnels</em>
                </p>
            </div>
        ) : (
            ""
        );
    return (
        <div className="blockClassic">
            <h3>Déchets acceptés</h3>
            <div className="dechets_acceptes">{listInfos}</div>
            {avertissementDechetsPros}
        </div>
    );
}

export function Competences(props) {
    if (!props.data || Object.entries(props.data).length === 0) {
        return "";
    }
    let listInfos = [];

    Object.keys(props.data).forEach((key) => {
        listInfos.push(<li key={"competences_" + key}>{props.data[key]}</li>);
    });

    return (
        <div className="blockClassic">
            <h3>Compétences</h3>
            <ul>{listInfos}</ul>
        </div>
    );
}

export function EPCIPartenaires(props) {
    if (!props.data || Object.entries(props.data).length === 0) {
        return "";
    }
    let listInfos = [];

    Object.keys(props.data).forEach((key) => {
        listInfos.push(
            <li key={"epci_partenaires_" + key}>{props.data[key]}</li>
        );
    });

    return (
        <div className="blockClassic">
            <h3>EPCI auxquels cet EPCI adhère</h3>
            <ul>{listInfos}</ul>
        </div>
    );
}

export function Horaires(props) {
    if (
        !props.data ||
        (!props.data["Ouverture"] &&
            !props.data["Fermeture"] &&
            !props.data["jours"])
    ) {
        return "";
    }
    let listInfos = [];

    if (props.data["jours"]) {
        listInfos.push(
            <div key="horaires_jours">
                <table className="calendar-table">
                    <thead>
                        <tr>
                            <th className="absent-td"></th>
                            <th>L</th>
                            <th>M</th>
                            <th>M</th>
                            <th>J</th>
                            <th>V</th>
                            <th>S</th>
                            <th>D</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="blue-td">Ouvert</td>
                            {Object.keys(props.data["jours"]).map(
                                (key, index) => {
                                    return (
                                        <td key={"horaires_jours_" + key}>
                                            {props.data["jours"][key] === true
                                                ? "X"
                                                : ""}
                                        </td>
                                    );
                                }
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (props.data["Ouverture"] && props.data["Ouverture"]["_text"]) {
        listInfos.push(
            <div key="horaires_ouverture">
                <p>
                    <strong>Ouverture</strong>
                </p>
                <p>{props.data["Ouverture"]["_text"]}</p>
            </div>
        );
    }

    if (props.data["Fermeture"] && props.data["Fermeture"]["_text"]) {
        listInfos.push(
            <div key="horaires_fermeture">
                <p>
                    <strong>Fermeture</strong>
                </p>
                <p>{props.data["Fermeture"]["_text"]}</p>
            </div>
        );
    }

    return (
        <div className="blockClassic">
            <h3>Horaires</h3>
            <p>
                Pour obtenir les horaires à jour de ce service, vous pouvez
                consulter le site du maître d'ouvrage.
            </p>
        </div>
    );
}

export function InformationsPrestataire(props) {
    if (!props.data || Object.entries(props.data).length === 0) {
        return "";
    }

    let urlMail = "mailto:" + props.data["email"];

    let web = props.data["web"];
    if (web && !web.startsWith("http")) {
        web = "http://" + web;
    }

    return (
        <div className="blockClassic">
            <ul className="listCenter">
                {!props.data["telephone"] ? (
                    ""
                ) : (
                    <li>
                        <span>Tél. :</span>
                        <span>{props.data["telephone"]}</span>
                    </li>
                )}
                {!props.data["fax"] ? (
                    ""
                ) : (
                    <li>
                        <span>Fax :</span>
                        <span>{props.data["fax"]}</span>
                    </li>
                )}
                {!web ? (
                    ""
                ) : (
                    <li>
                        <span>Site internet :</span>
                        <span>
                            <a href={web}>{web}</a>
                        </span>
                    </li>
                )}
                {!props.data["email"] ? (
                    ""
                ) : (
                    <li>
                        <span>E-mail :</span>
                        <span>
                            <a href={urlMail}>{props.data["email"]}</a>
                        </span>
                    </li>
                )}
                {!props.data["contact"] ? (
                    ""
                ) : (
                    <li>
                        <span>Contact :</span>
                        <span>{props.data["contact"]}</span>
                    </li>
                )}
                {!props.data["siret"] ? (
                    ""
                ) : (
                    <li>
                        <span>N° de SIRET :</span>
                        <span>{props.data["siret"]}</span>
                    </li>
                )}
                {!props.data["groupe"] ? (
                    ""
                ) : (
                    <li>
                        <span>Groupe :</span>
                        <span>{props.data["groupe"]}</span>
                    </li>
                )}
                {!props.data["proprietaire"] ? (
                    ""
                ) : (
                    <li>
                        <span>Appartenant à :</span>
                        <span>{props.data["proprietaire"]}</span>
                    </li>
                )}
                {!props.data["date_maj"] ? (
                    ""
                ) : (
                    <li>
                        <span>Mise à jour :</span>
                        <span>
                            {props.data["date_maj"]} -{" "}
                            {props.data["donnees_valide"]
                                ? "données validées par l'entreprise"
                                : "données non validées par l'entreprise"}
                        </span>
                    </li>
                )}
            </ul>
        </div>
    );
}

let handleCategorie = function (elements, doNotSort = false) {
    let listCatInfos = [];
    let data = [];

    if (typeof elements === "string" || elements instanceof String) {
        return [<li style={{ whiteSpace: "pre-wrap" }}>{elements}</li>];
    }

    for (var dechet in elements) {
        data.push(elements[dechet]);
    }

    if (!doNotSort) {
        data = data.sort();
    }

    // On trie les déchets par nom
    data.forEach((val) => {
        if (val !== "") {
            listCatInfos.push(<li>{val}</li>);
        }
    });

    return listCatInfos;
};

export function CategoriePrestation(props) {
    if (!props.data || Object.entries(props.data).length === 0) {
        return "";
    }

    let listInfos = handleCategorie(props.data, props.doNotSort);
    let listAdditionalInfos = "";

    if (props.additionalData) {
        listAdditionalInfos = <p>{props.additionalData}</p>;
    }

    if (listInfos.length > 0 || listAdditionalInfos.length > 0) {
        return (
            <div className="blockClassic">
                <h3>{props.title}</h3>
                <ul className="list-inline">{listInfos}</ul>
                {listAdditionalInfos}
            </div>
        );
    } else {
        return "";
    }
}

export function CategorieEtSousCategoriesPrestation(props) {
    if (!props.data || Object.entries(props.data).length === 0) {
        return "";
    }
    let listInfos = [];

    for (const dataName in props.data) {
        if (Object.hasOwnProperty.call(props.data, dataName)) {
            const elements = props.data[dataName];

            // on gère les sous catégories
            let listCatInfos = [];
            // on gère le cas où l'on a qu'une seule ligne d'objets
            let oneLiner = false;

            // soit on a un tableau avec plusieurs sources d'informations
            // (par exemple, collecteur et collecteur-autre)
            if (Array.isArray(elements)) {
                elements.forEach((element) => {
                    if (typeof element === "object") {
                        listCatInfos = listCatInfos.concat(
                            handleCategorie(element, props.doNotSort)
                        );
                    } else if (element !== "" && element !== undefined) {
                        listCatInfos.push(<li>{element}</li>);
                    }
                });
            }
            // soit on a directement un objet avec l'ensemble des déchets traités
            else if (typeof elements === "object") {
                listCatInfos = handleCategorie(elements);
            }
            // soit on a une chaine de caractères
            else if (elements !== "" && elements !== undefined) {
                if (elements === "1") {
                    listCatInfos = "Oui";
                    oneLiner = true;
                } else {
                    listCatInfos = elements;
                }
            }

            if (listCatInfos.length > 0) {
                let title = !props.displayTitle ? (
                    ""
                ) : (
                    <h4 className={oneLiner ? "h4-one-liner" : ""}>
                        {dataName}
                    </h4>
                );
                let listItems = oneLiner ? (
                    <span>{listCatInfos}</span>
                ) : (
                    <ul className="list-inline">{listCatInfos}</ul>
                );
                listInfos.push(
                    <div>
                        {title}

                        {listItems}
                    </div>
                );
            }
        }
    }

    if (listInfos.length > 0) {
        return (
            <div className="blockClassic">
                <h3>{props.title}</h3>
                {listInfos}
            </div>
        );
    } else {
        return "";
    }
}
