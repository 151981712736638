import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import WindowedSelect from "react-windowed-select";

import config from "./settings.js";
import Results from "./main/Results.js";
import DetailEquipement from "./main/DetailEquipement.js";

export class SelectField extends React.Component {
    constructor(props) {
        super(props);
        // we set default value

        const defaultLabel = this.props.defaultLabel
            ? this.props.defaultLabel
            : "Choisissez une valeur";
        const defaultValue = this.props.defaultValue
            ? this.props.defaultValue
            : "";
        this.state = {
            selection: { label: defaultLabel, value: defaultValue },
        };
        this.majSelection = this.majSelection.bind(this);
    }

    majSelection(e) {
        this.setState({
            selection: e,
        });
        // we call parent callback
        this.props.change(e.value);
    }

    testSamePropsData(oldData, newData) {
        // if we have two empty arrays, true
        if (oldData.length === 0 && newData.length === 0) {
            return true;
        }
        // if we have different sizes array
        if (oldData.length !== newData.length) {
            return false;
        }
        // we get all the ids from the data array
        let idsOld = oldData.map((element) => {
            return element[this.props.idKey];
        });
        // and we check that new data have the same elements
        return newData.every((element) => {
            return idsOld.includes(element[this.props.idKey]);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // if we are in the case where we need to reset the select field when
        // the props data are different
        if (this.props.resetOnChangeProps === true) {
            // we test that we really have different data
            if (!this.testSamePropsData(prevProps.data, this.props.data)) {
                // we reset the selection then
                const labelDefault = this.props.defaultLabel
                    ? this.props.defaultLabel
                    : "Choisissez une valeur";
                this.majSelection({ label: labelDefault, value: "" });
            }
        }
    }

    render() {
        const options = [];

        const customStyles = {
            menu: (provided) => ({ ...provided, width: "400px" }),
            option: (provided) => ({ ...provided, width: "400px" }),
            control: (provided) => ({ ...provided, width: "400px" }),
        };

        if (this.props.data) {
            this.props.data.forEach((element) => {
                options.push({
                    value: element[this.props.idKey],
                    label: element[this.props.labelKey],
                });
            });

            if (options.length > 500) {
                return (
                    <WindowedSelect
                        value={this.state.selection}
                        name={this.props.name}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => this.majSelection(e)}
                        options={options}
                        styles={customStyles}
                    />
                );
            }
        }

        return (
            <Select
                value={this.state.selection}
                name={this.props.name}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => this.majSelection(e)}
                options={options}
                styles={customStyles}
            />
        );
    }
}

function PopupDetails(props) {
    /**
     * Affiche les détails d'un équipement à partir des données fournies
     */
    // si on a bien choisi d'afficher les détails
    if (props.showDetails) {
        return (
            <div className="overlay" onClick={(e) => props.callbackClose(e)}>
                <div className="popup">
                    <a
                        className="close"
                        href="#close"
                        onClick={(e) => props.callbackClose(e)}
                    >
                        &times;
                    </a>
                    <div className="content">
                        <DetailEquipement
                            typeEquipement={props.typeEquipement}
                            typeSource={props.typeSource}
                            idEquipement={props.idEquipement}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return "";
    }
}

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            resources: [],
            centerCity: undefined,
            wastesType: [],
            currentWastes: [],
            servicesTypes: [],
            cities: [],

            error: "",
            warning: "",

            dechet: "",
            category: "",
            service: "",
            city: "",
            rayon: 5,
            queryPending: false,
            showDetails: false,
            typeEquipement: undefined,
            typeSource: undefined,
            idEquipement: undefined,
        };

        this.getOrdecData = this.getOrdecData.bind(this);
        this.lookupOrdecResults = this.lookupOrdecResults.bind(this);
        this.showDetailEquipement = this.showDetailEquipement.bind(this);
        this.hideDetailEquipement = this.hideDetailEquipement.bind(this);
        this.changeWasteCategory = this.changeWasteCategory.bind(this);
    }

    componentDidMount() {
        // on récupère les données pour remplir les formulaires
        this.getOrdecData();

        document.title = "QDQS - Chercher des solutions pour mes déchets";
    }

    getOrdecData() {
        // récupère les données auprès de l'API
        fetch(config.apiLink + "data/", {})
            .then((response) => response.json())
            .then((json) => {
                this.setState({
                    categories: json["categories"],
                    actorsTypes: json["actorsTypes"],
                    wastesType: json["wastesTypes"],
                    servicesTypes: json["servicesTypes"].concat([
                        { key: "tous", nom: "Tout type de service" },
                    ]),
                    cities: json["cities"],
                    error: "",
                    warning: "",
                });
            })
            .catch((e) => {
                this.setState({
                    error: "Impossible de récupérer les données. Contactez l'administrateur !",
                });
            });
    }

    lookupOrdecResults(e) {
        // on gère les cas où il manque des informations pour faire la requête
        if (this.state.dechet === "") {
            this.setState({
                error: "Il manque le choix du déchet !",
            });
            return;
        }
        if (this.state.service === "") {
            this.setState({
                error: "Il manque le choix du service !",
            });
            return;
        }

        // on met à 0 les résultats et on affiche le fait qu'une requête est en cours.
        this.setState({ queryPending: true, queryStatus: false });
        this.setState({ results: [], resources: [] });

        // constitution des paramètres de la requête
        const body = JSON.stringify(
            {
                dechet: this.state.dechet,
                service: this.state.service,
                commune: this.state.city !== "" ? this.state.city : false,
                rayon: this.state.rayon === "" ? 10 : this.state.rayon,
            }
            // {"dechet":7260,"service":"collecteur","commune":"69123","rayon":20}
        );
        fetch(config.apiLink + "search/", {
            body,
            method: "POST",
            credentials: "same-origin",
            mode: "cors",
        })
            .then(
                // on traite la réponse
                (response) => response.json()
            )
            .then((json) => {
                // on enregistre
                this.setState({
                    centerCity: json.city_center,
                    results: json.results,
                    departements: json.departements,
                    resources: json.resources,
                    queryPending: false,
                    queryStatus: json.fail_codes,
                    error: "",
                });
            });
    }

    changeWaste(newValue) {
        // mettre à jour le menu de l'état
        this.setState({
            dechet: newValue,
            error: "",
        });
    }

    changeWasteCategory(newValue) {
        // on met à jour la catégorie de déchet
        // on recharge donc la liste de déchets associés
        let wastes = [];
        this.state.wastesType.forEach((dechet) => {
            if (dechet.category_id === newValue) {
                wastes.push(dechet);
            }
        });

        this.setState({
            currentWastes: wastes,
            waste: "",
            category: newValue,
            clearSelect: true,
        });
    }

    changeService(newValue) {
        this.setState({
            service: newValue,
        });
    }

    changeCity(newValue) {
        this.setState({
            city: newValue,
        });
    }

    showDetailEquipement(typeEquipement, idEquipement, typeSource) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        /*
        Requires the corresponding function to handle the signal in parent page.
        window.onmessage = function(e) {
            if (e.data == 'scroll-top') {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }
        };
        */
        window.top.postMessage("scroll-top", "*");
        this.setState({
            showDetails: true,
            typeSource: typeSource,
            typeEquipement: typeEquipement,
            idEquipement: idEquipement,
        });
    }

    hideDetailEquipement(e) {
        // do not handle click when clicking on inner div and not on main div
        if (e.target !== e.currentTarget) {
            return;
        }
        this.setState({
            showDetails: false,
            typeEquipement: undefined,
            typeSource: undefined,
            idEquipement: undefined,
        });
    }

    render() {
        let results = (
            <Results
                data={this.state.results}
                resources={this.state.resources}
                departements={this.state.departements}
                actorsTypes={this.state.actorsTypes}
                status={this.state.queryPending}
                centerCity={this.state.centerCity}
                failCodes={this.state.queryStatus}
                callbackDetails={this.showDetailEquipement}
                radius={this.state.rayon ?? 10}
            />
        );

        // affichage d'un disclaimer quand il y a des erreurs
        let error = "";
        if (this.state.error !== "") {
            error = (
                <div className="error disclaimer-message">
                    <span>{this.state.error}</span>
                </div>
            );
        }

        return (
            <div>
                <h1>Pour un professionnel</h1>

                <p>
                    Vous êtes un professionnel et vous recherchez une solution
                    pour un type de déchets sur un territoire donné,
                    sélectionnez votre déchet dans la liste et indiquez un rayon
                    autour d’une commune :
                </p>
                {error}
                <form>
                    <div className="block-inputs-ordec">
                        <div>
                            <SelectField
                                data={this.state.servicesTypes}
                                name="type_service"
                                defaultLabel="Choisissez un type de service"
                                defaultValue="tous"
                                change={this.changeService.bind(this)}
                                idKey="key"
                                labelKey="nom"
                            />
                        </div>
                        <div>
                            <SelectField
                                data={this.state.categories}
                                name="categorie_dechets"
                                defaultLabel="Choisissez une catégorie de déchets"
                                change={this.changeWasteCategory.bind(this)}
                                idKey="id"
                                labelKey="name"
                            />
                            <SelectField
                                data={this.state.currentWastes}
                                name="dechets"
                                resetOnChangeProps={true}
                                defaultLabel="Choisissez un type de déchets"
                                change={this.changeWaste.bind(this)}
                                idKey="dechet_id"
                                labelKey="nom"
                            />
                        </div>
                        <div>
                            <SelectField
                                data={this.state.cities}
                                name="communes"
                                defaultLabel="Choisissez une commune"
                                change={this.changeCity.bind(this)}
                                idKey="code_insee"
                                labelKey="nom_commune"
                            />
                        </div>
                        <div>
                            <input
                                className="input-rayon"
                                value={this.state.rayon}
                                type="number"
                                step="5"
                                min="5"
                                onChange={(event) =>
                                    this.setState({
                                        rayon: +event.target.value,
                                    })
                                }
                                style={{ width: 370 + "px" }}
                            />
                            <span style={{ marginLeft: 10 + "px" }}>km</span>
                        </div>
                    </div>
                </form>
                <p>
                    <button onClick={this.lookupOrdecResults}>
                        Rechercher
                    </button>
                </p>
                <div className="explanations">
                    <p>
                        Retrouvez en quelques clics sous forme cartographique et
                        de liste, pour le déchet sélectionné :
                    </p>
                    <ul>
                        <li>
                            Les <strong>déchèteries</strong> auxquelles vous
                            avez accès ;
                        </li>
                        <li>
                            Les références indispensables à consulter (les{" "}
                            <strong>éco-organismes</strong> en particulier) ;
                        </li>
                        <li>
                            Les <strong>prestataires</strong> de service ayant
                            déclaré collecter ou traiter le déchet sélectionné.
                        </li>
                    </ul>

                    <p>
                        Chaque réponse (prestataire ou déchèterie) est alors
                        détaillée dans une fiche.
                    </p>
                    <p>
                        {/* <a
                            className="bouton_article url"
                            title="Le fonctionnement de l’outil QDQS"
                            href="https://www.ordec-auvergne-rhone-alpes.fr/"
                        >
                            Le fonctionnement de l’outil QDQS
                        </a> */}
                    </p>
                </div>
                {results}

                <PopupDetails
                    showDetails={this.state.showDetails}
                    typeEquipement={this.state.typeEquipement}
                    typeSource={this.state.typeSource}
                    idEquipement={this.state.idEquipement}
                    callbackClose={this.hideDetailEquipement}
                />
            </div>
        );
    }
}

export default MainPage;
