import {
    AccueilPros,
    Agrements,
    Competences,
    DechetsAcceptes,
    EPCIPartenaires,
    Horaires,
    Territoire,
} from "./modules";
import validateEmail from "../utils/utils";

export default function Decheterie(props) {
    if (props.data["rubriques"] === undefined) {
        return <div>Aucun détail actuellement disponible ici.</div>;
    }

    let metas = props.data["rubriques"];

    let address = props.data["address"];

    // Informations de contact
    let informations = [];
    for (const label in metas["Informations générales"]) {
        if (
            Object.hasOwnProperty.call(metas["Informations générales"], label)
        ) {
            const element = metas["Informations générales"][label];
            let value =
                element === true ? "Oui" : element === false ? " - " : element;

            let regex_telephone =
                /^(\d{2})\s?(\d{2})\s?(\d{2})\s?(\d{2})\s?(\d{2})$/;
            let match = regex_telephone.exec(value);

            // soit c'est un mail
            if (validateEmail(value)) {
                informations.push(
                    <li key={"info_gen_" + label}>
                        <strong>{label} : </strong>
                        <a href={"mailto:" + value}>{value}</a>
                    </li>
                );
            }
            // soit c'est un numéro de téléphone
            else if (match) {
                informations.push(
                    <li key={"info_gen_" + label}>
                        <strong>{label} : </strong>
                        {match.slice(1).join(".")}
                    </li>
                );
            }
            // soit c'est un lien
            else if (value.startsWith("http")) {
                informations.push(
                    <li key={"info_gen_" + label}>
                        <strong>{label} : </strong>
                        <a href={value}>{value}</a>
                    </li>
                );
            }
            // soit c'est une information autre
            else {
                informations.push(
                    <li key={"info_gen_" + label}>
                        <strong>{label} : </strong>
                        {value}
                    </li>
                );
            }
        }
    }

    return (
        <div>
            <div className="header-details">
                <div className="col-sm-12">
                    <h2>{props.data["name"]}</h2>
                    <div className="blockAddress">
                        <p>
                            {address["rue"]}{" "}
                            {address["complement"]
                                ? " - " + address["complement"]
                                : ""}
                        </p>
                        <p>
                            {address["cp"]} {address["ville"]}{" "}
                        </p>
                    </div>
                </div>
            </div>
            <div className="container-details">
                <div className="left-details">
                    <div className="blockClassic">
                        <h3>Informations générales</h3>
                        <ul>{informations}</ul>
                    </div>

                    <AccueilPros data={metas["Accueil des professionnels"]} />

                    <Agrements data={metas["Agréments"]} />

                    <Territoire data={metas["Territoire"]} />
                </div>
                <div className="right-details">
                    <Horaires
                        dechetsPro={props.dechetsPro}
                        data={metas["Horaires"]}
                    />

                    <DechetsAcceptes
                        data={metas["Déchets acceptés"]}
                        dechetsPro={props.dechetsPro}
                        dechetsRefuses={metas["Déchets refusés pour les pros"]}
                    />

                    <Competences data={metas["Compétences"]} />

                    <EPCIPartenaires
                        data={metas["EPCI auxquels cet EPCI adhère"]}
                    />
                </div>
            </div>
        </div>
    );
}
