import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import { EditableParam, EditableParamSelectable } from "../utils/common.js";

import config from "../settings.js";

function withParams(Component) {
    return (props) => <Component {...props} params={useParams()} />;
}

/**
 * Displays div grouping wastes by existing categories. Allow for removing
 * any waste from the set.
 *
 * @param {Array} wastes wastes list
 * @param {Array} wastesCategories wastes categories list
 * @param {callable} deleteCallback function called when the user tries to remove a waste
 */
export function DisplayWastesByCategories(props) {
    // check that we have any waste or categories
    if (
        !props.wastesCategories ||
        !props.wastes ||
        !Array.isArray(props.wastesCategories) ||
        !Array.isArray(props.wastes)
    ) {
        return <p>Impossible d'afficher ces déchets</p>;
    }

    return props.wastesCategories.map((category) => {
        return (
            <div
                key={"wastes-category-" + category.category_id}
                className={"wastes-category " + category.category_class_name}
            >
                <p>
                    <strong>{category.category_name}</strong>
                </p>
                <div className="wastes-category-list">
                    {props.wastes.map((waste) => {
                        if (waste.category_id === category.category_id) {
                            return (
                                <div
                                    key={
                                        "waste-cat-sub-element-" +
                                        waste.dechet_id
                                    }
                                    className="waste-in-category"
                                >
                                    <em>{waste.nom}</em>
                                    <img
                                        src="/delete.svg"
                                        className="waste-link-delete"
                                        onClick={() =>
                                            props.deleteCallback(
                                                waste.dechet_id
                                            )
                                        }
                                    />
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        );
    });
}

/**
 * Show the equipment form to enable editing data related to an actor equipment.
 * Show general information and waste list.
 */
function UpdateEquipementForm(props) {
    const [name, setName] = useState(props.data.nom);
    const [wasteAddition, addWaste] = useState([]);
    const [wasteAdditionNames, addWasteName] = useState([]);

    // Update waste list
    const changeWastes = (newValue) => {
        addWaste([newValue.value]);
        addWasteName([newValue.label]);
    };

    const addWasteToEquipment = () => {
        if (Array.isArray(wasteAddition) && wasteAddition.length > 0) {
            props.addCallback(wasteAddition[0]);
        }
    };

    return (
        <div>
            <h2>Informations générales</h2>
            <p>
                <EditableParam
                    editCallback={setName}
                    saveCallback={() => console.log("Saving")}
                    cancelLabel="Annuler les modifications"
                    name="Nom"
                    value={name}
                    forceEdition={true}
                />
            </p>
            <h2>Déchets gérés au sein de votre activité</h2>
            <DisplayWastesByCategories
                wastes={props.selectedWastes}
                wastesCategories={props.wastesCategories}
                deleteCallback={props.removeCallback}
            />
            <div>
                <label>Ajouter un déchet à ceux que vous traitez : </label>
                <EditableParamSelectable
                    inputData={props.wastes}
                    idKey="dechet_id"
                    labelKey="nom"
                    hideId={true}
                    singleSelection={true}
                    forceEdition={true}
                    currentSelection={wasteAddition}
                    currentSelectionNames={wasteAdditionNames}
                    editCallback={changeWastes}
                    saveCallback={addWasteToEquipment}
                    sendLabel="Ajouter"
                    name="Déchet"
                />
            </div>
        </div>
    );
}

/**
 * Update equipment module. Requires three parameters to be able to process
 * an equipment: the actor ID, the update ID and the key to check the user
 * is authorized to edit this equipment.
 *
 * Actor = equipment.
 */
class UpdateEquipement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: undefined,
            wastes: false,
            selectedWastes: false,
            categories: false,
            loaded: false,
        };

        this.removeWasteFromEquipment =
            this.removeWasteFromEquipment.bind(this);
        this.addWasteToEquipment = this.addWasteToEquipment.bind(this);
    }

    /**
     * Call API to retrieve wastes and wastes categories list.
     */
    getWastesList() {
        // retrieve data from API
        fetch(config.apiLink + "wastes/")
            .then((response) => response.json())
            .then((json) => {
                this.setState({
                    wastes: json["wastes"] ?? [],
                    selectedWastes: json["wastes"] ?? [],
                    categories: json["categories"] ?? [],
                });
            })
            .catch((e) => {});
    }

    /**
     * Retrieve data related to current actor from API based on current URL
     * parameters (key, etc.).
     *
     * @param {int} updateId current update ID
     * @param {int} actorId current actor ID
     * @param {string} key current update authentication token
     */
    getEquipmentDetailsFromKey(updateId, actorId, key) {
        fetch(
            config.apiLink +
                "data/update/" +
                updateId +
                "/get/details/" +
                actorId +
                "/from/key/",
            {
                method: "POST",
                credentials: "same-origin",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ key: key }),
            }
        )
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    this.setState({
                        loaded: true,
                        data: undefined,
                    });
                    return false;
                }
            })
            .then((json) => {
                if (json) {
                    console.log("JSON received", json);
                    this.setState({
                        data: json.actor,
                        loaded: true,
                    });
                }
            });
    }

    componentDidMount() {
        // retrieve equipements details when not already available
        if (
            this.props.params.key &&
            this.props.params.actorId &&
            (!this.state.data || this.state.data === undefined)
        ) {
            this.getEquipmentDetailsFromKey(
                this.props.params.updateId,
                this.props.params.actorId,
                this.props.params.key
            );
        }

        // retrieve wastes
        if (!this.state.wastes) {
            this.getWastesList();
        }
    }

    removeWasteFromEquipment(wasteId) {
        let _wastes = JSON.parse(JSON.stringify(this.state.selectedWastes));
        _wastes.forEach((waste, j) => {
            if (waste.dechet_id === wasteId) {
                _wastes.splice(j, 1);
            }
        });
        this.setState({
            selectedWastes: _wastes,
        });
    }

    addWasteToEquipment(wasteId) {
        let _selectedWastes = JSON.parse(
            JSON.stringify(this.state.selectedWastes)
        );
        let alreadyFound = _selectedWastes.find(
            (v, i) => v.dechet_id === wasteId
        );
        if (alreadyFound === undefined) {
            let waste = this.state.wastes.find(
                (v, i) => v.dechet_id === wasteId
            );
            _selectedWastes.push(waste);
            this.setState({
                selectedWastes: _selectedWastes.sort((a, b) => {
                    const nameA = a.nom.toUpperCase();
                    const nameB = b.nom.toUpperCase();
                    return nameA.localeCompare(nameB);
                }),
            });
        }
    }

    render() {
        let error = "";
        let output = "";
        if (this.state.data === undefined && this.state.loaded) {
            error = <p>Aucun équipement n'a été trouvé dans la base !</p>;
        } else if (this.state.data === undefined && !this.state.loaded) {
            error = <p>Chargement en cours...</p>;
        } else if (this.state.data !== undefined) {
            output = (
                <UpdateEquipementForm
                    wastes={this.state.wastes}
                    selectedWastes={this.state.selectedWastes}
                    wastesCategories={this.state.categories}
                    data={this.state.data}
                    removeCallback={this.removeWasteFromEquipment}
                    addCallback={this.addWasteToEquipment}
                />
            );
        }
        return (
            <div>
                <p>
                    <Link to="/">Retour à la page principale</Link>
                </p>
                <>
                    <h1>
                        Mise à jour des données dans la base ORDEC relatives à
                        votre installation
                    </h1>
                </>
                <div className="update-explanations">
                    <p>
                        Si vous avez été contacté(e) lors d'une campagne de mise
                        à jour des informations relatives à votre installation,
                        merci d'apporter les modifications nécessaires sur le
                        formulaire ci-contre.
                    </p>

                    <p>
                        Après avoir saisi vos modifications, n'oubliez pas de
                        cliquer sur le bouton "Enregistrer" pour qu'elles soient
                        soumises à l'administrateur du site.
                    </p>

                    <p>
                        Attention : vos modifications n'apparaîtront dans le
                        carnet d'adresses qu'une fois validées par
                        l'administrateur.
                    </p>
                </div>

                {error}
                {output}

                <p>
                    <button className="pure-button pure-button-primary">
                        Envoyer le formulaire
                    </button>
                </p>
                <p>Merci de votre participation !</p>
            </div>
        );
    }
}

export default withParams(UpdateEquipement);
