import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function MainNavBar(props) {
    const { pathname } = useLocation();
    const [appMode, setAppMode] = useState(
        pathname === "/particuliers" ? "pub" : "pro"
    );

    return (
        <div className="main-app-choices">
            <Link
                to="/"
                className={
                    "main-app-choice" + (appMode === "pro" ? " active" : "")
                }
                onClick={() => setAppMode("pro")}
            >
                Professionnels
            </Link>
            <Link
                to="/particuliers"
                className={
                    "main-app-choice" + (appMode === "pub" ? " active" : "")
                }
                onClick={() => setAppMode("pub")}
            >
                Particuliers
            </Link>
        </div>
    );
}
