import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import config from "../settings.js";

export default function SiteTester(props) {
    const [sites, setSites] = useState([]);
    const [sitesTested, setSitesTested] = useState({});
    const [isTesting, setTesting] = useState(false);
    const [error, setError] = useState(undefined);

    const getListeSites = () => {
        // récupère les données auprès de l'API
        fetch(config.apiLink + "existing/field/website/", {
            headers: {
                Authorization: "Bearer " + props.token,
            },
        })
            .then((response) => response.json())
            .then((json) => {
                setSites(json);
                setError(undefined);
            })
            .catch((e) => {
                setError(
                    "Impossible de récupérer les données. Contactez l'administrateur !"
                );
            });
    };

    useEffect(() => {
        // on récupère les données pour remplir les formulaires
        getListeSites();
        document.title =
            "QDQS - Liste des installations répertoriées avec site web";
    }, []);

    const testSites = () => {
        setSitesTested({});
        setTesting(true);
        // récupère les données auprès de l'API
        fetch(config.apiLink + "test/websites/", {
            headers: {
                Authorization: "Bearer " + props.token,
            },
        })
            .then((response) => response.json())
            .then((json) => {
                setSitesTested(json["tested"]);
                setTesting(false);
            })
            .catch((e) => {
                setError(
                    "Impossible de récupérer les données. Contactez l'administrateur !"
                );
                setTesting(false);
            });
    };

    let renderedSites = [];
    let orderedSites = sites;
    if (!orderedSites) {
        return (
            <div className="sites-web-manquants">
                <h1>Liste des installations avec site web renseigné</h1>
                <p>Aucun site actuellement disponible.</p>
            </div>
        );
    }
    // On trie les déchets par nom
    orderedSites = orderedSites.sort((a, b) => {
        var x = a.nom.toLowerCase();
        var y = b.nom.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
    });

    orderedSites.forEach((site) => {
        let url = site.valeur;
        if (!url.startsWith("http")) {
            url = "http://" + site.valeur;
        }

        let tested = sitesTested[site.acteur_id];
        if (tested !== undefined && tested === true) {
            tested = (
                <img className="resultat-existence-site" src="/check.svg" />
            );
        } else if (tested !== undefined && tested === "redirections") {
            tested = (
                <span>
                    <img
                        className="resultat-existence-site"
                        src="/problem.svg"
                    />{" "}
                    Périmée
                </span>
            );
        } else if (tested !== undefined) {
            tested = <img className="resultat-existence-site" src="/no.svg" />;
        }
        renderedSites.push(
            <tr>
                <td>{site.nom}</td>
                <td>
                    <a href={url}>{url}</a>
                </td>
                <td>{tested}</td>
            </tr>
        );
    });

    return (
        <div className="sites-web-manquants">
            <h1>Liste des installations avec site web renseigné</h1>
            <p>
                <button onClick={testSites} disabled={isTesting}>
                    Tester les sites existants
                </button>
            </p>
            {isTesting ? (
                <p>En cours de tests, cela peut prendre du temps...</p>
            ) : (
                ""
            )}
            <table>{renderedSites}</table>
        </div>
    );
}
