import React, { useState } from "react";
import { Link } from "react-router-dom";
import { EditableParam, EditableParamSelectable } from "../utils/common.js";

import config from "../settings.js";

function Waste(props) {
    const changeValue = (propsName, newVal) => {
        let data_ = JSON.parse(JSON.stringify(props.data));
        data_[propsName] = newVal;
        props.editCallback(data_);
    };

    const changeCategory = (newValue) => {
        let data_ = JSON.parse(JSON.stringify(props.data));

        const categoryId = newValue.value;
        let newCategory = undefined;
        props.categories.forEach((element) => {
            if (element.category_id === categoryId) {
                newCategory = JSON.parse(JSON.stringify(element));
            }
        });

        if (newCategory !== undefined) {
            data_.category_id = newCategory.category_id;
            data_.category_name = newCategory.category_name;
            data_.category_class_name = newCategory.category_class_name;
        }
        props.editCallback(data_);
    };

    return (
        <div className={"waste-element " + props.data.category_class_name}>
            <EditableParam
                editCallback={(e) => changeValue("nom", e)}
                saveCallback={() => props.saveCallback(props.data.dechet_id)}
                name="Nom"
                value={props.data.nom}
            />
            <EditableParamSelectable
                inputData={props.categories}
                idKey="category_id"
                labelKey="category_name"
                hideId={true}
                singleSelection={true}
                withoutGroupedOptions={true}
                currentSelection={props.data.category_id}
                currentSelectionNames={props.data.category_name}
                editCallback={changeCategory}
                saveCallback={() => props.saveCallback(props.data.dechet_id)}
                name="Catégorie"
                value={props.data.category_name}
            />
            <WasteLinks
                wasteId={props.data.dechet_id}
                correspondances={props.data.correspondances}
                addCallback={(newVal) => props.addWasteLinkCallback(newVal)}
                editCallback={(linkId, newVal) =>
                    props.editWasteLinkCallback(linkId, newVal)
                }
                deleteCallback={(newVal) =>
                    props.deleteWasteLinkCallback(newVal)
                }
            />
        </div>
    );
}

function WasteLink(props) {
    const [name, setName] = useState(props.data.name);
    const [type, setType] = useState(props.data.link_type);

    const deleteLink = () => {
        if (
            window.confirm("Confirmez-vous la suppression de ce lien ?") == true
        ) {
            props.deleteCallback(props.data.link_id);
        }
    };

    return (
        <div className="waste-link-element">
            <img
                src="/delete.svg"
                className="waste-link-delete"
                onClick={deleteLink}
            />
            <EditableParam
                editCallback={(newVal) => setName(newVal)}
                saveCallback={() => {
                    setName(name);
                    props.saveCallback(props.data.link_id, name, type);
                }}
                className="waste-link-input"
                value={name}
            />
            <EditableParam
                editCallback={(newVal) => setType(newVal)}
                saveCallback={() => {
                    setType(type);
                    props.saveCallback(props.data.link_id, name, type);
                }}
                className="waste-link-input"
                name="type"
                value={type}
            />
        </div>
    );
}

function WasteLinks(props) {
    const [isAddingLink, enableAddition] = useState(false);
    const [newLinkValue, setNewLinkValue] = useState("");
    const [newLinkType, setNewLinkType] = useState("");

    let links = [];

    const cancelNewLink = () => {
        setNewLinkValue("");
        setNewLinkType("");
        enableAddition(false);
    };

    const saveNewLink = () => {
        let newLink = {
            name: newLinkValue,
            type: newLinkType,
        };
        props.addCallback(newLink);
    };

    const deleteLink = (linkId) => {
        props.deleteCallback(linkId);
    };

    const editLink = (linkId, name, type) => {
        let newLink = {
            name: name,
            link_type: type,
        };
        props.editCallback(linkId, newLink);
    };

    const showLinkInput = () => {
        enableAddition(true);
    };

    if (props.correspondances) {
        if (Array.isArray(props.correspondances)) {
            props.correspondances.forEach((link) => {
                links.push(
                    <WasteLink
                        key={"waste-link-" + link.link_id}
                        data={link}
                        deleteCallback={deleteLink}
                        saveCallback={editLink}
                    />
                );
            });
        }
    }

    return (
        <div>
            <p key={"waste-links-" + props.wasteId}>
                <span onClick={showLinkInput}>
                    <img src="/plus.svg" className="add-link" />
                    Ajouter une correspondance
                </span>
            </p>
            <div className={isAddingLink ? "shown" : "hidden"}>
                <label htmlFor={"new_link_value_" + props.wasteId}>
                    <strong>Valeur : </strong>
                </label>
                <input
                    type="text"
                    name={"new_link_value_" + props.wasteId}
                    value={newLinkValue}
                    onChange={(e) => setNewLinkValue(e.target.value)}
                />
                <p>
                    <label htmlFor={"new_link_type_" + props.wasteId}>
                        <strong>Type : </strong>
                    </label>
                    <input
                        type="text"
                        name={"new_link_type_" + props.wasteId}
                        value={newLinkType}
                        onChange={(e) => setNewLinkType(e.target.value)}
                    />
                </p>

                <button onClick={() => cancelNewLink()}>Annuler</button>
                <button onClick={() => saveNewLink()}>Envoyer</button>
            </div>
            <div
                className="wastes-links"
                key={"waste-links-content-" + props.wasteId}
            >
                {links}
            </div>
        </div>
    );
}

class Wastes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            wastes: [],
            error: false,
            errorMessage: false,
        };

        this.saveWaste = this.saveWaste.bind(this);
    }

    componentDidMount() {
        // on récupère les données pour remplir les formulaires
        this.getData();
        document.title = "QDQS - Gestion des déchets";
    }

    getData() {
        // récupère les données auprès de l'API
        fetch(config.apiLink + "wastes/", {
            headers: {
                Authorization: "Bearer " + this.props.token,
            },
        })
            .then((response) => response.json())
            .then((json) => {
                this.setState({
                    wastes: json["wastes"] ?? [],
                    categories: json["categories"] ?? [],
                });
            })
            .catch((e) => {
                this.setState({
                    error: "Impossible de récupérer les données. Contactez l'administrateur !",
                });
            });
    }

    addLinkToWaste(resourceId, newVal) {
        fetch(config.apiLink + "waste/" + resourceId + "/add/link/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.token,
            },
            body: JSON.stringify(newVal),
        })
            .then((response) => response.json())
            .then((json) => {
                let _wastes = JSON.parse(JSON.stringify(this.state.wastes));
                this.state.wastes.forEach((resource, i) => {
                    if (resource.dechet_id === resourceId) {
                        resource.correspondances.push({
                            link_id: json.correspondance_id,
                            link_type: newVal.type,
                            name: newVal.name,
                        });
                        _wastes[i].correspondances = resource.correspondances;
                    }
                });

                this.setState({
                    wastes: _wastes,
                    error: json.fail,
                    errorMessage: json.message,
                });

                setTimeout(() => {
                    this.setState({
                        error: false,
                        errorMessage: false,
                    });
                }, 2000);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    editLinkFromWaste(resourceId, linkId, newVal) {
        fetch(
            config.apiLink +
                "waste/" +
                resourceId +
                "/update/link/" +
                linkId +
                "/",
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.props.token,
                },
                body: JSON.stringify(newVal),
            }
        )
            .then((response) => response.json())
            .then((json) => {
                let _wastes = JSON.parse(JSON.stringify(this.state.wastes));
                _wastes.forEach((waste, j) => {
                    if (waste.dechet_id === resourceId) {
                        waste.correspondances.forEach((link, i) => {
                            if (link.link_id === linkId) {
                                waste.correspondances[i] = {
                                    link_id: linkId,
                                    ...newVal,
                                };
                            }
                        });
                        _wastes[j].correspondances = waste.correspondances;
                    }
                });

                this.setState({
                    wastes: _wastes,
                    error: json.fail,
                    errorMessage: json.message,
                });

                setTimeout(() => {
                    this.setState({
                        error: false,
                        errorMessage: false,
                    });
                }, 2000);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    changeWaste(id, newVal) {
        let wastes = JSON.parse(JSON.stringify(this.state.wastes));
        wastes[id] = newVal;
        this.setState({
            wastes: wastes,
        });
    }

    removeLinkFromWaste(resourceId, linkId) {
        fetch(
            config.apiLink +
                "waste/" +
                resourceId +
                "/remove/link/" +
                linkId +
                "/",
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.props.token,
                },
            }
        )
            .then((response) => response.json())
            .then((json) => {
                let _wastes = JSON.parse(JSON.stringify(this.state.wastes));
                if (!json.fail) {
                    _wastes.forEach((waste, j) => {
                        if (waste.dechet_id === resourceId) {
                            waste.correspondances.forEach((link, i) => {
                                if (link.link_id === linkId) {
                                    waste.correspondances.splice(i, 1);
                                }
                            });
                        }
                    });
                }
                this.setState({
                    wastes: _wastes,
                    error: json.fail,
                    errorMessage: json.message,
                });

                setTimeout(() => {
                    this.setState({
                        error: false,
                        errorMessage: false,
                    });
                }, 2000);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    saveWaste(arrayId, resourceId) {
        const waste = this.state.wastes[arrayId];
        this.setState({ errorMessage: false });

        if (waste.dechet_id !== resourceId) {
            alert(
                "Attention, le déchet ne semble pas correspondre à l'objet modifié. Veuillez réessayer ou signaler le problème !"
            );
        } else {
            fetch(config.apiLink + "waste/" + resourceId + "/update/", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.props.token,
                },
                body: JSON.stringify(waste),
            })
                .then((response) => response.json())
                .then((json) => {
                    this.setState({
                        error: json.fail,
                        errorMessage: json.message,
                    });

                    setTimeout(() => {
                        this.setState({
                            error: false,
                            errorMessage: false,
                        });
                    }, 2000);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    render() {
        let wastes = [];

        this.state.wastes.forEach((resource, i) => {
            wastes.push(
                <Waste
                    key={"waste-" + i}
                    editCallback={(newVal) => this.changeWaste(i, newVal)}
                    saveCallback={(wasteId) => this.saveWaste(i, wasteId)}
                    addWasteLinkCallback={(newVal) =>
                        this.addLinkToWaste(resource.dechet_id, newVal)
                    }
                    editWasteLinkCallback={(linkId, newVal) =>
                        this.editLinkFromWaste(
                            resource.dechet_id,
                            linkId,
                            newVal
                        )
                    }
                    deleteWasteLinkCallback={(linkId) =>
                        this.removeLinkFromWaste(resource.dechet_id, linkId)
                    }
                    data={resource}
                    categories={this.state.categories}
                />
            );
        });

        return (
            <div>
                <p>
                    <Link to="/">Retour à la page d'accueil</Link>
                </p>
                <p className="error-block">
                    <span
                        className={
                            (this.state.error ||
                            this.state.errorMessage !== false
                                ? "alert-shown "
                                : "alert-hidden ") +
                            (this.state.error === false
                                ? " success-message"
                                : " error-message")
                        }
                    >
                        {this.state.errorMessage}
                    </span>
                </p>
                <div className="wastes-list">{wastes}</div>
            </div>
        );
    }
}

export default Wastes;
