import {
    InformationsPrestataire,
    CategoriePrestation,
    CategorieEtSousCategoriesPrestation,
    DechetsAcceptes,
    AccueilPros,
    Agrements,
    Territoire,
} from "./modules";
import validateEmail from "../utils/utils";

export function QDQSPrestataire(props) {
    let metas = props.data["rubriques"];
    let remarques = props.data["remarques"];
    if (remarques) {
        remarques = remarques.replaceAll("\\r\\n", "\n");
    }
    let address = props.data["address"];

    let dataExploitations = {
        "Plateforme de compostage ou de broyage de déchets verts et autres fermentescibles":
            [
                metas[
                    "plateforme-de-compostage-ou-de-broyage-de-dechets-verts-et-autres-fermentescibles"
                ],
                metas[
                    "plateforme-de-compostage-ou-de-broyage-de-dechets-verts-et-autres-fermentescibles-autre"
                ],
            ],
        "Centre de tri / transfert": [
            metas["centre-de-tri-transfert"],
            metas["centre-de-tri-transfert-autre"],
        ],
        "Centre de valorisation et de recyclage des DD (procédés physico-chimiques ou biologiques, régénération, banalisation des DASRI, ...)":
            [
                metas[
                    "centre-de-valorisation-et-de-recyclage-des-dd-procedes-physico-chimiques-ou-biologiques-regeneration-banalisation-des-dasri"
                ],
                metas[
                    "centre-de-valorisation-et-de-recyclage-des-dd-procedes-physico-chimiques-ou-biologiques-regeneration-banalisation-des-dasri-autre"
                ],
            ],
        "Centre de valorisation ou de recyclage des DND": [
            metas["centre-de-valorisation-ou-de-recyclage-des-dnd"],
            metas["centre-de-valorisation-ou-de-recyclage-des-dnd-autre"],
        ],
        "Centre de préparation de DND en vue de valorisation": [
            metas["centre-de-preparation-de-dnd-en-vue-de-valorisation"],
            metas["centre-de-preparation-de-dnd-en-vue-de-valorisation-autre"],
        ],
        "Remploi et réutilisation": [
            metas["reemploi-reutilisation"],
            metas["reemploi-reutilisation-autre"],
        ],
        ISDI: metas["isdi"],
        ISDND: metas["isdnd"],
        ISDD: metas["isdd"],
        UIOM: metas["uiom"],

        "Déchèterie des collectivités": metas["decheterie-des-collectivites"],
        "Déchèterie des professionnels": metas["decheterie-des-professionnels"],
        "Recyclerie / ressourcerie": metas["recyclerie-ressourcerie"],
        Cimenterie: metas["cimenterie"],
        Méthanisations: [
            metas["methanisations"],
            metas["methanisations-autre"],
        ],
        "Maturation de mâchefers": metas["maturation-de-machefers"],
        // $maturationMachefers = get_post_meta($postID, 'maturation-de-machefers', true);
    };

    return (
        <div>
            <div className="header-details">
                <div className="col-sm-12">
                    <h2>{props.data["name"]}</h2>
                    <div className="blockAddress">
                        <p>
                            {address["rue"]}{" "}
                            {address["complement"]
                                ? " - " + address["complement"]
                                : ""}
                        </p>
                        <p>
                            {address["cp"]} {address["ville"]}{" "}
                        </p>
                    </div>
                </div>
            </div>
            <div className="container-details">
                <div className="left-details">
                    <InformationsPrestataire data={props.data} />
                </div>
                <div className="right-details">
                    <CategoriePrestation
                        data={metas["collecteur"]}
                        title="Collecteur"
                    />

                    <CategorieEtSousCategoriesPrestation
                        data={dataExploitations}
                        title="Exploitant"
                        displayTitle={true}
                    />

                    <CategorieEtSousCategoriesPrestation
                        data={[
                            metas["demantelement"],
                            metas["demantelement-autre"],
                        ]}
                        title="Démantèlement"
                        doNotSort={false}
                        displayTitle={false}
                    />

                    <CategoriePrestation
                        title="Récupération multi-matériaux  / gestionnaire de points de regroupement"
                        data={
                            metas[
                                "recuperation-multi-materiaux-gestionnaire-de-points-de-regroupement"
                            ]
                        }
                        additionalData={
                            metas[
                                "recuperation-multi-materiaux-gestionnaire-de-points-de-regroupement-autre"
                            ]
                        }
                    />

                    <CategorieEtSousCategoriesPrestation
                        data={[
                            metas["agrements-et-autorisations"],
                            metas["agrements-et-autorisations-autre"],
                        ]}
                        title="Agréments et autorisations"
                        doNotSort={true}
                        displayTitle={false}
                    />

                    <CategoriePrestation data={remarques} title="Remarques" />
                </div>
            </div>
        </div>
    );
}

export function SinoePrestataire(props) {
    let metas = props.data["rubriques"];
    if (!metas) {
        return <p>Non disponible.</p>;
    }
    let remarques = props.data["remarques"];
    if (remarques) {
        remarques = remarques.replaceAll("\\r\\n", "\n");
    }
    let address = props.data["address"];

    let dataExploitations = {
        "Plateforme de compostage ou de broyage de déchets verts et autres fermentescibles":
            [
                metas[
                    "plateforme-de-compostage-ou-de-broyage-de-dechets-verts-et-autres-fermentescibles"
                ],
                metas[
                    "plateforme-de-compostage-ou-de-broyage-de-dechets-verts-et-autres-fermentescibles-autre"
                ],
            ],
        "Centre de tri / transfert": [
            metas["centre-de-tri-transfert"],
            metas["centre-de-tri-transfert-autre"],
        ],
        "Centre de valorisation et de recyclage des DD (procédés physico-chimiques ou biologiques, régénération, banalisation des DASRI, ...)":
            [
                metas[
                    "centre-de-valorisation-et-de-recyclage-des-dd-procedes-physico-chimiques-ou-biologiques-regeneration-banalisation-des-dasri"
                ],
                metas[
                    "centre-de-valorisation-et-de-recyclage-des-dd-procedes-physico-chimiques-ou-biologiques-regeneration-banalisation-des-dasri-autre"
                ],
            ],
        "Centre de valorisation ou de recyclage des DND": [
            metas["centre-de-valorisation-ou-de-recyclage-des-dnd"],
            metas["centre-de-valorisation-ou-de-recyclage-des-dnd-autre"],
        ],
        "Centre de préparation de DND en vue de valorisation": [
            metas["centre-de-preparation-de-dnd-en-vue-de-valorisation"],
            metas["centre-de-preparation-de-dnd-en-vue-de-valorisation-autre"],
        ],
        "Remploi et réutilisation": [
            metas["reemploi-reutilisation"],
            metas["reemploi-reutilisation-autre"],
        ],
        ISDI: metas["isdi"],
        ISDND: metas["isdnd"],
        ISDD: metas["isdd"],
        UIOM: metas["uiom"],

        "Déchèterie des collectivités": metas["decheterie-des-collectivites"],
        "Déchèterie des professionnels": metas["decheterie-des-professionnels"],
        "Recyclerie / ressourcerie": metas["recyclerie-ressourcerie"],
        Cimenterie: metas["cimenterie"],
        Méthanisations: [
            metas["methanisations"],
            metas["methanisations-autre"],
        ],
        "Maturation de mâchefers": metas["maturation-de-machefers"],
        // $maturationMachefers = get_post_meta($postID, 'maturation-de-machefers', true);
    };
    // Informations de contact
    let informations = [];
    for (const label in metas["Informations générales"]) {
        if (
            Object.hasOwnProperty.call(metas["Informations générales"], label)
        ) {
            const element = metas["Informations générales"][label];
            let value =
                element === true ? "Oui" : element === false ? " - " : element;

            let regex_telephone =
                /^(\d{2})\s?(\d{2})\s?(\d{2})\s?(\d{2})\s?(\d{2})$/;
            let match = regex_telephone.exec(value);

            // soit c'est un mail
            if (validateEmail(value)) {
                informations.push(
                    <li key={"info_gen_" + label}>
                        <strong>{label} : </strong>
                        <a href={"mailto:" + value}>{value}</a>
                    </li>
                );
            }
            // soit c'est un numéro de téléphone
            else if (match) {
                informations.push(
                    <li key={"info_gen_" + label}>
                        <strong>{label} : </strong>
                        {match.slice(1).join(".")}
                    </li>
                );
            }
            // soit c'est un lien
            else if (value.startsWith("http")) {
                informations.push(
                    <li key={"info_gen_" + label}>
                        <strong>{label} : </strong>
                        <a href={value}>{value}</a>
                    </li>
                );
            }
            // soit c'est une information autre
            else {
                informations.push(
                    <li key={"info_gen_" + label}>
                        <strong>{label} : </strong>
                        {value}
                    </li>
                );
            }
        }
    }
    return (
        <div>
            <div className="header-details">
                <div className="col-sm-12">
                    <h2>{props.data["name"]}</h2>
                    <div className="blockAddress">
                        <p>
                            {address["rue"]}{" "}
                            {address["complement"]
                                ? " - " + address["complement"]
                                : ""}
                        </p>
                        <p>
                            {address["cp"]} {address["ville"]}{" "}
                        </p>
                    </div>
                </div>
            </div>
            <div className="container-details">
                <div className="left-details">
                    <div className="blockClassic">
                        <h3>Informations générales</h3>
                        <ul>{informations}</ul>
                    </div>

                    <AccueilPros data={metas["Accueil des professionnels"]} />

                    <Agrements data={metas["Agréments"]} />

                    <Territoire data={metas["Territoire"]} />
                </div>
                <div className="right-details">
                    <CategorieEtSousCategoriesPrestation
                        data={dataExploitations}
                        title="Exploitant"
                        displayTitle={true}
                    />

                    <DechetsAcceptes
                        data={metas["Déchets acceptés"]}
                        dechetsPro={props.dechetsPro}
                        dechetsRefuses={metas["Déchets refusés pour les pros"]}
                    />

                    <CategoriePrestation data={remarques} title="Remarques" />
                </div>
            </div>
        </div>
    );
}
