import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
} from "react-router-dom";

import "./App.css";

import MainPage from "./MainPage";
import PublicMainPage from "./PublicMainPage";

import DetailEquipement from "./main/DetailEquipement";
import MainNavBar from "./main/MainNavBar";

import UpdateEquipement from "./survey/UpdateEquipement";

import AdminMainPage from "./admin/AdminMainPage";
import MissingField from "./admin/MissingField";
import SiteTester from "./admin/SiteTester";
import AdminResources from "./admin/Resources";
import Wastes from "./admin/Wastes";
import Actors from "./admin/Actors";
import EditActor from "./admin/EditActor";
import DataUpdate from "./admin/DataUpdate";
import Tests from "./admin/Tests";

import useToken from "./utils/useToken";
import AuthVerify from "./utils/AuthVerify";

const PublicContainer = () => {
    // Contains Main nav bar for public pages
    return (
        <div>
            <p>
                <MainNavBar />
            </p>
            <Outlet />
        </div>
    );
};

const AdminContainer = () => {
    // Contains Admin routes for logged in users
    const { token, removeToken, setToken, logout } = useToken();

    return (
        <div>
            <Routes>
                <Route
                    exact
                    path="/qdqs_admin/*"
                    element={
                        <AdminMainPage
                            token={token}
                            removeToken={removeToken}
                            setToken={setToken}
                        />
                    }
                >
                    {token && (
                        <>
                            <Route
                                path="tests/*"
                                element={<Tests />}>
                                <Route
                                    exact
                                    path=":field_name-manquant/"
                                    element={<MissingField token={token} />}
                                ></Route>
                                <Route
                                    exact
                                    path="tester-sites-web/"
                                    element={<SiteTester token={token} />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="ressources/"
                                element={<AdminResources token={token} />}
                            ></Route>
                            <Route
                                exact
                                path="dechets/"
                                element={<Wastes token={token} />}
                            ></Route>
                            <Route
                                exact
                                path="maj/"
                                element={<DataUpdate token={token} />}
                            ></Route>
                            <Route
                                exact
                                path="acteurs/"
                                element={<Actors token={token} />}
                            ></Route>
                            <Route
                                exact
                                path="acteur/:idacteur/modifier"
                                element={
                                    <EditActor mode="edition" token={token} />
                                }
                            ></Route>
                            <Route
                                exact
                                path="acteur/ajouter"
                                element={
                                    <EditActor mode="addition" token={token} />
                                }
                            ></Route>
                        </>
                    )}
                </Route>
            </Routes>

            <AuthVerify logOut={logout} />
        </div>
    );
};

function App() {
    return (
        <Router>
            <div className="App">
                {/* Main public routes routes */}
                <Routes>
                    <Route exact path="/" element={<PublicContainer />}>
                        <Route exact path="" element={<MainPage />}></Route>
                        <Route
                            exact
                            path="particuliers"
                            element={<PublicMainPage />}
                        ></Route>
                        <Route exact path="detail/*">
                            <Route
                                path=":typeSource/:typeEquipement/:idEquipement"
                                element={<DetailEquipement />}
                            />
                        </Route>
                        <Route exact path="mise-a-jour/">
                            <Route
                                path=":updateId/:actorId/:key"
                                element={<UpdateEquipement />}
                            />
                        </Route>
                    </Route>
                </Routes>

                {/* Admin routes */}
                <AdminContainer />
            </div>
        </Router>
    );
}

export default App;
