import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import config from "../settings.js";

export default function MissingField(props) {
    const [sites, setSites] = useState([]);
    const [error, setError] = useState(undefined);

    let params = useParams();
    const fieldStudied = params?.field_name ?? "site-web"


    const getListeSites = () => {
        // récupère les données auprès de l'API
        fetch(config.apiLink + "missing/field/"+fieldStudied+"/", {
            headers: {
                Authorization: "Bearer " + props.token,
            },
        })
            .then((response) => response.json())
            .then((json) => {
                setSites(json)
                setError(undefined)
            })
            .catch((e) => {
                setError("Impossible de récupérer les données. Contactez l'administrateur !");
            });
    }

    useEffect(() => {
        // on récupère les données pour remplir les formulaires
        getListeSites();
        document.title =
            "QDQS - Liste des installations répertoriées sans "+fieldStudied;
    }, [params]);

    let orderedSites = sites;
    if (!orderedSites) return null;
    // On trie les déchets par nom
    orderedSites = orderedSites.sort((a, b) => {
        var x = a.nom.toLowerCase();
        var y = b.nom.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
    });

    let renderedSites = []
    orderedSites.forEach((site) => {
        renderedSites.push(<li>{site.nom}</li>);
    });

    return (
            <div className="sites-web-manquants">
                <h1>
                    Liste des installations sans l'information suivante : {fieldStudied}
                </h1>
                <ul>{renderedSites}</ul>
            </div>
        );
}
